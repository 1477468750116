import { Typography } from "@mui/material";
import HomeImage from "../../assets/images/HomePage.jpg";

const HomePage = () => {
  return (
    <div id={"homepage"}>
      <Typography
        textAlign={"center"}
        fontWeight={600}
        fontFamily='"Open Sans", sans-serif;'
        color={"white"}
        sx={{ fontSize: { md: 50, lg: 70, sm: 30 }, pt: 2, pb: 2 }}
      >
        Data-Centric AI Revolution
      </Typography>
      <img src={HomeImage} alt={"homepage"} />
    </div>
  );
};

export default HomePage;
