import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import YoutubeEmbed from "../YoutubeEmbed";

const GridItemYoutube = styled(Grid)(() => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 20
}))

export default function Video() {
  return (
    <Grid Container spacing={2} justifyContent={'center'}>
        <GridItemYoutube item xs={8}>
          <YoutubeEmbed embedId="Brk1yJqDjUo" />
        </GridItemYoutube>
    </Grid>
  );
}
