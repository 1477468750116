import React from "react";
import { useModulosBody } from "./context/ModulosBodyProvider";

export default function Header() {
  const [, setModulosBody] = useModulosBody();

  const goToHomePage = () => {
    setModulosBody((existingBody) => {
      console.log(existingBody);
      return {
        ...existingBody,
        activeMainView: "HomePage",
      };
    });
  };

  return (
    <div id="header">
      <img src="/logo.svg" onClick={goToHomePage} alt="" />
    </div>
  );
}
