import { Backdrop, CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { useState } from "react";

const BackdropContext = React.createContext();
// let fetching;

export function useBackdrop() {
  return useContext(BackdropContext);
}

export default function BackdropProvider({ children }) {
  const [backdrop, setBackdrop] = useState(false);
  
  const fetching = (value) => {
    setBackdrop(value)
  }

  return (
    <BackdropContext.Provider value={{fetching}}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </BackdropContext.Provider>
  );
}
