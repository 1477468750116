import React from "react";
import { useModulosBody } from "../context/ModulosBodyProvider";
import AutoMLPlatform from "./AutoMLPlatform";
import Demo from "./Demo/Demo";
import Documentation from "./Documentation";
import Modulos from "./Modulos.ai";
import DataCentric from "./DataCentric";
import Video from "./Video";
import Vision from "./Vision";
import HomePage from "./Home";

export default function MainView() {
  let [modulosBody] = useModulosBody();
  let { activeMainView } = modulosBody;
  let ViewToRender;
  switch (activeMainView) {
    case "HomePage":
      ViewToRender = HomePage;
      break;
    case "Use cases":
      ViewToRender = Demo;
      break;
    case "AutoML Platform":
      ViewToRender = AutoMLPlatform;
      break;
    case "Modulos.ai":
      ViewToRender = Modulos;
      break;
    case "Resources":
      ViewToRender = Documentation;
      break;
    case "Video":
      ViewToRender = Video;
      break;
    case "Data-Centric AI":
      ViewToRender = DataCentric;
      break;
    case "Vision":
      ViewToRender = Vision;
      break;
    default:
      ViewToRender = HomePage;
      break;
  }
  
  return (
    <div
      id={`main-view`}
      className={`${
        activeMainView === "HomePage" || activeMainView === undefined || activeMainView === 'Vision'
          ? "homepage"
          : null
      }`}
    >
      <ViewToRender />
    </div>
  );
}
