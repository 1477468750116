import ModulosBody from "./components/ModulosBody";
import Header from "./components/Header";
import ModulosBodyProvider from "./components/context/ModulosBodyProvider";
import BackdropProvider from "components/context/BackdropProvider";
import ThemeProvider from "./components/context/ThemeProvider";
import { CookiesProvider } from 'react-cookie';

function App() {
  return (
    <div className="App">
      <ThemeProvider>
        <ModulosBodyProvider>
          <Header />
          <BackdropProvider>
            <CookiesProvider>
              <ModulosBody />
            </CookiesProvider>
          </BackdropProvider>
        </ModulosBodyProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
