import React from "react";
import { useCookies } from 'react-cookie';

import { useModulosBody } from "../../context/ModulosBodyProvider";
import DemoItem from "./DemoItem";
import GalaxyTypeID from "./GalaxyTypeID";
import HousingPricePrediction from "./HousingPricePrediction";
import PropertyPriceIndicator from "./PropertyPriceIndicator";
import BikesDemandForecast from "./BikesDemandForecast";
import ChurnRatePredictor from "./ChurnRatePredictor";
import LongTermDeposit from "./LongTermDeposit";

// SVGs
import Analytics from "./svg/Analytics";
import Bikes from "./svg/Bikes";
// import Coronavirus from "./svg/Coronavirus";
import Galaxy from "./svg/Galaxy";
import MedicalResearch from "./svg/MedicalResearch";
import Pricing from "./svg/Pricing";
import Stats from "./svg/Stats";
import Histopathological from "./Histopathological";
// import Covid from "./Covid";
import CovidDetails from "./CovidDetails";
import LoginPage from "./Login";

const data = [
  {
    title: "Housing <br> Price Prediction",
    key: "Housing Price Prediction",
    subtitle: "Tabular Regression",
    img: "pricing",
    svg: Pricing,
    component: HousingPricePrediction,
  },
  {
    title: "Galaxy <br> Type",
    key: "Galaxy Type",
    subtitle: "Image Classification",
    img: "galaxy",
    svg: Galaxy,
    component: GalaxyTypeID,
  },
  {
    title: "Churn <br> Rate Predictor",
    key: "Churn Rate Predictor",
    subtitle: "Tabular Classification",
    img: "analytics",
    svg: Analytics,
    component: ChurnRatePredictor,
  },
  {
    title: "Bikes <br> Demand Forecast",
    key: "Bikes Demand Forecast",
    subtitle: "Time Series",
    img: "bikes",
    svg: Bikes,
    component: BikesDemandForecast,
  },
  {
    title: "Histopathological <br> Cancer Detection",
    key: "Histopathological Cancer Detection",
    subtitle: "Image Classification",
    img: "medical-research",
    svg: MedicalResearch,
    component: Histopathological,
  },
  {
    title: "Long Term <br> Deposit Campaign",
    key: "Long Term Deposit Campaign",
    subtitle: "Tabular Classification with probability",
    img: "stats1",
    svg: Stats,
    component: LongTermDeposit,
  },
];

export default function Demo() {
  let [modulosBody] = useModulosBody();
  let { activeDemo } = modulosBody;
  let ViewToRender = null;
  
  const [cookies] = useCookies(['username']);
  
  data.forEach((dataElem) => {
    if (activeDemo === dataElem.key) {
      ViewToRender = dataElem.component;
    }
  });

  switch (activeDemo) {
    case "Use Case: Property Price Indicator":
      ViewToRender = PropertyPriceIndicator;
      break;

    case "Covid Details":
      ViewToRender = CovidDetails;
      break;

    default:
      break;
  }
  let Demo = (
    <div id="demo">
      {data.map((item) => {
        return <DemoItem key={item.img} item={item} />;
      })}
    </div>
  );

  return cookies.username ? ViewToRender ? (
    <div className="demo-view-container">
      <ViewToRender />
    </div>
  ) : (
    Demo
  ) : (
    <LoginPage/>
  );
}
