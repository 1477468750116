import { useState } from "react";
import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MuiSlider from "@mui/material/Slider";
import Dialog from "@mui/material/Dialog";
import { useModulosBody } from "../../context/ModulosBodyProvider";
import { styled } from "@mui/material/styles";
import axios from "axios";
import DemoViewHeader from "./DemoViewHeader";
import Divider from "@mui/material/Divider";
import { useBackdrop } from "components/context/BackdropProvider";
import ModelDetails from "./ModelDetails";
import ModelInfo from "../../../assets/images/feature_importance (1).jpeg";

const Select = styled(MuiSelect)(() => ({
  borderRadius: 4,
  height: 40,
  "& >*": {
    border: "none",
  },
}));
const Slider = styled(MuiSlider)(() => ({
  color: "white",
  "& .MuiSlider-thumb": {
    border: "2px solid #7AA89C",
  },
  "& .MuiSlider-track": {
    color: "#7AA89C",
  },
}));
const baseURL = process.env.REACT_APP_BACKEND;

export default function HousingPricePrediction() {
  const [, setModulosBody] = useModulosBody();
  const [areaType, setAreaType] = useState(5000);
  const [areaIncome, setAreaIncome] = useState(5);
  const [householdDensity, setHouseholdDensity] = useState(100);
  const [latitude, setlatitude] = useState(32);
  const [longitude, setlongitude] = useState(-124);
  const [houseAge, sethouseAge] = useState(1);
  const [totalRooms, settotalRooms] = useState(0);
  const [totalBedrooms, settotalBedrooms] = useState(0);
  const [oceanProximity, setOceanProximity] = useState("NEAR BAY");
  const [dialogOpen, setDialogOpen] = useState(false);

  const [modelOpen, setModelOpen] = useState(false);

  const [predict, setPredict] = useState(null);
  const { fetching } = useBackdrop();
  function onSubmitForm() {
    setPredict(null);
    fetching(true);
    axios
      .post(`${baseURL}/predict`, {
        population: areaType,
        median_income: areaIncome,
        latitude,
        longitude,
        households: householdDensity,
        housing_median_age: houseAge,
        avg_number_rooms: totalRooms,
        avg_number_bedrooms: totalBedrooms,
        ocean_proximity: oceanProximity,
        sample_ids_generated: getRandomInt(10000),
      })
      .then(({ data }) => {
        setPredict(data.data?.median_house_value__predicted);
        setDialogOpen(true);
      })
      .finally(() => {
        fetching(false);
      });
  }

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  function setLatitude(num) {
    setlatitude(num);
  }
  function setLongitude(num) {
    setlongitude(num);
  }
  function setHouseAge(num) {
    sethouseAge(num);
  }
  function setTotalRooms(num) {
    settotalRooms(num);
  }
  function setTotalBedrooms(num) {
    settotalBedrooms(num);
  }

  return (
    <>
      <DemoViewHeader name="Housing Price Prediction" thirdComponent={false} />
      <div id="housing-price-prediction">
        <form>
          <div className="left-form-controls">
            <div className="has-select form-control">
              <span>Area Type:</span>
              <Select
                onChange={(e) => setAreaType(e.target.value)}
                value={areaType}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="5000">Small Village</MenuItem>
                <MenuItem value="20000">Small Town</MenuItem>
                <MenuItem value="1000000">Mid-Size City</MenuItem>
                <MenuItem value="2000000">Large</MenuItem>
              </Select>
            </div>
            <Divider />

            <div className="has-select form-control">
              <span>Area Income:</span>
              <Select
                onChange={(e) => setAreaIncome(e.target.value)}
                value={areaIncome}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="5">Small</MenuItem>
                <MenuItem value="10">Medium</MenuItem>
                <MenuItem value="15">High</MenuItem>
              </Select>
            </div>
            <Divider />

            <div className="has-slider form-control">
              <span>Latitude:</span>
              <Slider
                value={latitude}
                valueLabelDisplay="auto"
                onChange={(e) => setLatitude(e.target.value)}
                min={32}
                max={42}
                step={0.01}
              />

              <input
                type="number"
                min="32"
                max="42"
                className="slider-value"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
              />
            </div>
            <Divider />

            <div className="has-slider form-control">
              <span>Longitude:</span>
              <Slider
                min={-124}
                max={-114}
                step={0.01}
                value={longitude}
                valueLabelDisplay="auto"
                onChange={(e) => setLongitude(e.target.value)}
              />
              <input
                type="number"
                min="-124"
                max="-114"
                className="slider-value"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
              />
            </div>
            <Divider />

            <div className="has-select form-control">
              <span>Households Density:</span>
              <Select
                onChange={(e) => setHouseholdDensity(e.target.value)}
                value={householdDensity}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="100">Low</MenuItem>
                <MenuItem value="3000">Medium</MenuItem>
                <MenuItem value="6000">High</MenuItem>
              </Select>
            </div>
          </div>

          <div className="right-form-controls">
            <div className="has-slider form-control">
              <span>House Age: </span>
              <Slider
                min={1}
                max={52}
                step={1}
                value={houseAge}
                valueLabelDisplay="auto"
                onChange={(e) => setHouseAge(e.target.value)}
              />
              <input
                type="number"
                min="1"
                max="52"
                className="slider-value"
                value={houseAge}
                onChange={(e) => setHouseAge(e.target.value)}
              />
            </div>
            <Divider />

            <div className="has-slider form-control">
              <span>Nr. of rooms:</span>
              <Slider
                min={0}
                max={20}
                value={totalRooms}
                valueLabelDisplay="auto"
                onChange={(e) => setTotalRooms(e.target.value)}
              />
              <input
                type="number"
                min="0"
                max="20"
                className="slider-value"
                value={totalRooms}
                onChange={(e) => setTotalRooms(e.target.value)}
              />
            </div>
            <Divider />

            <div className="has-slider form-control">
              <span>Nr. of bedrooms:</span>
              <Slider
                min={0}
                max={4}
                value={totalBedrooms}
                valueLabelDisplay="auto"
                onChange={(e) => setTotalBedrooms(e.target.value)}
              />
              <input
                type="number"
                min="0"
                max="4"
                className="slider-value"
                value={totalBedrooms}
                onChange={(e) => setTotalBedrooms(e.target.value)}
              />
            </div>
            <Divider />

            <div className="has-select form-control">
              <span>Ocean Proximity:</span>
              <Select
                onChange={(e) => setOceanProximity(e.target.value)}
                value={oceanProximity}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="NEAR BAY">NEAR BAY</MenuItem>
                <MenuItem value="NEAR OCEAN">NEAR OCEAN</MenuItem>
                <MenuItem value="ISLAND">ISLAND</MenuItem>
                <MenuItem value="INLAND">INLAND</MenuItem>
                <MenuItem value="<1H OCEAN">{"<1H OCEAN"}</MenuItem>
              </Select>
            </div>
          </div>
        </form>
        <div className="action-buttons">
          <div className="random-values" onClick={() => setModelOpen(true)}>
            Model details
          </div>
          <div onClick={onSubmitForm} className="submit-form-button">
            Predict
          </div>
        </div>
        <ModelDetails
          open={modelOpen}
          onClose={() => setModelOpen(false)}
          image={ModelInfo}
        />
        <Dialog
          onClose={() => setDialogOpen(false)}
          open={dialogOpen && predict}
        >
          <div className="housing-prediction-result">
            <h2>House Estimated Price:</h2>
            <h1>{fromatHousePrice(predict?.toFixed(2))} USD</h1>
            <div
              onClick={() => {
                setModulosBody((existingBody) => {
                  return {
                    ...existingBody,
                    activeDemo: "Use Case: Property Price Indicator",
                  };
                });
              }}
              className="standard-primary-button"
            >
              Use Case
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}

function fromatHousePrice(price) {
  if (!price) return price;
  price = parseInt(price);
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}
