import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import VisionImg from '../../assets/images/productVision.jpg';

const GridItemYoutube = styled(Grid)(() => ({
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: 20,
  marginBottom: 20,
}));

export default function Vision() {
  return (
    <Grid className="vision-container" Container spacing={2} justifyContent={"center"}>
      <GridItemYoutube item md={10} xs={11}>
          <img src={VisionImg} alt={'vision'}/>
      </GridItemYoutube>
    </Grid>
  );
}
