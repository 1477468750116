import { Grid } from "@mui/material";
import React from "react";

import MainPNG from "../../assets/images/qr_main.png";

export default function Documentation() {
  return (
    <div style={{ margin: "0px 6rem" }}>
      <Grid container spacing={12} justifyContent="center" alignItems={'center'}>
        <Grid item xs={4}>
          <img width="100%" src={MainPNG} alt="main" />
        </Grid>
      </Grid>
    </div>
  );
}
