import React from "react";

export default function Pricing() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6:85)">
        <path
          d="M27.0569 79.6094C27.5778 80.1296 28.4215 80.1296 28.9423 79.6094L35.6089 72.9428L33.7235 71.0574L27.9996 76.7813L3.21871 52.0004L18.2758 36.9433L16.3904 35.0579L0.390624 51.0577C-0.129552 51.5785 -0.129552 52.4223 0.390624 52.9431L27.0569 79.6094Z"
          fill="#637E81"
        />
        <path
          d="M79.9911 27.8543L77.3245 3.85464C77.2555 3.23421 76.7659 2.74398 76.1455 2.67562L52.1458 0.00898999C51.7441 -0.0333271 51.3437 0.107296 51.0566 0.391797L41.7234 9.72501L43.6088 11.6104L52.4889 2.73031L74.7991 5.20749L77.2763 27.5112L72.0446 32.7436L69.5108 15.7021C71.3298 14.9795 72.3636 13.0524 71.9593 11.1377C71.5557 9.22306 69.8311 7.87803 67.8754 7.95159C65.9204 8.02581 64.3025 9.49715 64.0441 11.4372C63.7856 13.3767 64.962 15.2204 66.8299 15.8037L68.8923 29.6701L50.2754 11.0583C49.7546 10.5375 48.9108 10.5375 48.39 11.0583L41.7234 17.7249L43.6088 19.6103L49.3327 13.8864L66.1137 30.6674L63.0565 33.7247L64.9419 35.6101L68.9418 31.6101C69.0173 31.5242 69.0811 31.4285 69.1313 31.3263L69.6911 35.0899L67.0564 37.7246L68.9418 39.61L70.1788 38.3724L71.0258 44.0553C71.2269 45.6959 72.3109 47.0943 73.8493 47.6985C75.3883 48.3026 77.1338 48.0155 78.3981 46.9504C79.6617 45.8853 80.2411 44.2141 79.9072 42.595L77.4534 31.0978L79.6083 28.9435C79.8948 28.657 80.0354 28.2567 79.9911 27.8543ZM67.9991 10.6677C68.7354 10.6677 69.3324 11.2647 69.3324 12.001C69.3324 12.7373 68.7354 13.3343 67.9991 13.3343C67.2628 13.3343 66.6658 12.7373 66.6658 12.001C66.6658 11.2647 67.2628 10.6677 67.9991 10.6677ZM77.2965 43.1367C77.4241 43.7975 77.1806 44.4759 76.6604 44.9042C76.1409 45.3326 75.428 45.442 74.8037 45.1894C74.1794 44.9368 73.7432 44.3632 73.667 43.694L72.5322 36.0261L75.2139 33.3445L77.2965 43.1367Z"
          fill="#637E81"
        />
        <path
          d="M9.72526 51.8581L18.3913 43.1921L20.2767 45.0775L11.6107 53.7435L9.72526 51.8581Z"
          fill="#637E81"
        />
        <path
          d="M13.8559 55.9908L22.5219 47.3248L24.408 49.2102L15.7413 57.8762L13.8559 55.9908Z"
          fill="#637E81"
        />
        <path
          d="M17.99 60.1235L23.0564 55.0571L24.9425 56.9425L19.8754 62.0089L17.99 60.1235Z"
          fill="#637E81"
        />
        <path
          d="M22.1234 64.2576L25.7243 60.6573L27.6097 62.5427L24.0095 66.1436L22.1234 64.2576Z"
          fill="#637E81"
        />
        <path
          d="M26.2568 68.3909L28.9235 65.7249L30.8089 67.6103L28.1429 70.2764L26.2568 68.3909Z"
          fill="#637E81"
        />
        <path
          d="M47.9994 73.3335C52.5416 73.3316 56.9634 71.8707 60.6125 69.1656L70.2282 78.7814C71.8539 80.4064 74.4886 80.4064 76.1136 78.7814C77.7385 77.1564 77.7385 74.521 76.1136 72.896L66.2752 63.0576L66.1046 63.2282C71.2093 54.9712 70.0883 44.3079 63.3794 37.2923C56.6698 30.2762 46.0671 28.6811 37.5906 33.4122C29.1135 38.1432 24.9053 48.0051 27.3551 57.3989C29.8043 66.792 38.2918 73.3433 47.9994 73.3335ZM74.2282 74.7814C74.8122 75.3654 74.8122 76.312 74.2282 76.896C73.6442 77.48 72.6976 77.48 72.1136 76.896L62.6658 67.4495C63.3917 66.7627 64.0681 66.0263 64.6912 65.2458L74.2282 74.7814ZM47.9994 33.3341C58.3085 33.3341 66.6658 41.6914 66.6658 52.0005C66.6658 62.3096 58.3085 70.6669 47.9994 70.6669C37.6902 70.6669 29.3329 62.3096 29.3329 52.0005C29.344 41.6959 37.6948 33.3451 47.9994 33.3341Z"
          fill="#637E81"
        />
        <path
          d="M47.9994 68.0003C56.8358 68.0003 63.9991 60.837 63.9991 52.0005C63.9991 43.164 56.8358 36.0007 47.9994 36.0007C39.1629 36.0007 31.9996 43.164 31.9996 52.0005C32.0093 60.8331 39.1668 67.9905 47.9994 68.0003ZM47.9994 38.6674C55.3632 38.6674 61.3325 44.6367 61.3325 52.0005C61.3325 59.3643 55.3632 65.3337 47.9994 65.3337C40.6355 65.3337 34.6662 59.3643 34.6662 52.0005C34.674 44.6399 40.6388 38.6752 47.9994 38.6674Z"
          fill="#637E81"
        />
        <path
          d="M49.3327 57.3338H46.666C45.9297 57.3338 45.3327 56.7368 45.3327 56.0005H42.6661C42.6661 58.2094 44.4571 60.0004 46.666 60.0004V62.667H49.3327V60.0004C51.5416 60.0004 53.3326 58.2094 53.3326 56.0005V54.6671C53.3326 52.4582 51.5416 50.6672 49.3327 50.6672H46.666C45.9297 50.6672 45.3327 50.0702 45.3327 49.3339V48.0006C45.3327 47.2642 45.9297 46.6672 46.666 46.6672H49.3327C50.069 46.6672 50.666 47.2642 50.666 48.0006H53.3326C53.3326 45.7916 51.5416 44.0006 49.3327 44.0006V41.334H46.666V44.0006C44.4571 44.0006 42.6661 45.7916 42.6661 48.0006V49.3339C42.6661 51.5428 44.4571 53.3338 46.666 53.3338H49.3327C50.069 53.3338 50.666 53.9308 50.666 54.6671V56.0005C50.666 56.7368 50.069 57.3338 49.3327 57.3338Z"
          fill="#1F2E3C"
        />
        <path
          d="M6.66657 32.0012H15.5734L24.5583 38.4185C24.7842 38.5806 25.055 38.6678 25.333 38.6678C25.5895 38.6671 25.8408 38.5929 26.0569 38.4543C26.5185 38.1561 26.7496 37.606 26.6396 37.0676L25.6266 32.0012H33.3329C37.0132 31.9967 39.9949 29.0143 39.9995 25.3346V6.66823C39.9949 2.98794 37.0132 0.00620518 33.3329 0.00164795H6.66657C2.98628 0.00620518 0.00455342 2.98794 -3.8147e-06 6.66823V25.3346C0.00455342 29.0143 2.98628 31.9967 6.66657 32.0012ZM2.66663 6.66823C2.66663 4.45927 4.45762 2.66828 6.66657 2.66828H33.3329C35.5418 2.66828 37.3328 4.45927 37.3328 6.66823V25.3346C37.3328 27.5436 35.5418 29.3346 33.3329 29.3346H23.9997C23.5993 29.3346 23.2197 29.5143 22.9665 29.8242C22.7132 30.1347 22.613 30.5423 22.6931 30.9342L23.3597 34.2955L16.7706 29.5891C16.5466 29.4257 16.2771 29.3365 15.9998 29.3346H6.66657C4.45762 29.3346 2.66663 27.5436 2.66663 25.3346V6.66823Z"
          fill="#637E81"
        />
        <path
          d="M29.033 25.8732H22.6049C22.1427 25.8732 21.7646 25.4951 21.7646 25.033V21.0416C21.7646 20.0333 20.9663 19.2351 19.958 19.2351C18.9496 19.2351 18.1514 20.0333 18.1514 21.0416V25.033C18.1514 25.4951 17.7733 25.8732 17.3111 25.8732H10.967C10.5049 25.8732 10.1267 25.4951 10.1267 25.033V14.2774C10.1267 13.5212 10.5049 12.8069 11.0931 12.3868L19.5378 6.29477C19.8319 6.0847 20.2101 6.0847 20.5042 6.29477L28.9489 12.3868C29.5371 12.8069 29.9153 13.5212 29.9153 14.2774V25.075C29.8733 25.4951 29.4951 25.8732 29.033 25.8732ZM23.4451 24.2347H28.2347V14.2774C28.2347 14.0673 28.1087 13.8573 27.9406 13.7312L20 7.97533L12.0594 13.6892C11.8913 13.8153 11.7653 14.0253 11.7653 14.2354V24.1927H16.5549V21.0416C16.5549 19.151 18.1094 17.5965 20 17.5965C21.8906 17.5965 23.4451 19.151 23.4451 21.0416V24.2347Z"
          fill="#1F2E3C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5042 6.29477L28.9489 12.3868C29.5371 12.8069 29.9153 13.5212 29.9153 14.2774V25.075C29.8733 25.4951 29.4951 25.8732 29.033 25.8732H22.6049C22.1427 25.8732 21.7646 25.4951 21.7646 25.033V21.0416C21.7646 20.0333 20.9663 19.2351 19.958 19.2351C18.9496 19.2351 18.1514 20.0333 18.1514 21.0416V25.033C18.1514 25.4951 17.7733 25.8732 17.3111 25.8732H10.967C10.5049 25.8732 10.1267 25.4951 10.1267 25.033V14.2774C10.1267 13.5212 10.5049 12.8069 11.0931 12.3868L19.5378 6.29477C19.8319 6.0847 20.2101 6.0847 20.5042 6.29477ZM10.9187 12.1427C10.9189 12.1425 10.9185 12.1428 10.9187 12.1427L19.3635 6.05065C19.7619 5.76607 20.2801 5.76608 20.6785 6.05065L20.6797 6.05147L29.1233 12.1427C29.1235 12.1428 29.1231 12.1425 29.1233 12.1427C29.7896 12.6189 30.2153 13.424 30.2153 14.2774V25.0899L30.2138 25.1048C30.1573 25.67 29.6575 26.1732 29.033 26.1732H22.6049C21.977 26.1732 21.4646 25.6608 21.4646 25.033V21.0416C21.4646 20.199 20.8006 19.5351 19.958 19.5351C19.1153 19.5351 18.4514 20.199 18.4514 21.0416V25.033C18.4514 25.6608 17.9389 26.1732 17.3111 26.1732H10.967C10.3392 26.1732 9.82674 25.6608 9.82674 25.033V14.2774C9.82674 13.424 10.2524 12.6189 10.9187 12.1427ZM28.2347 14.2774C28.2347 14.0673 28.1087 13.8573 27.9406 13.7312L20 7.97533L12.0594 13.6892C11.8913 13.8153 11.7653 14.0253 11.7653 14.2354V24.1927H16.5549V21.0416C16.5549 19.151 18.1094 17.5965 20 17.5965C21.8906 17.5965 23.4451 19.151 23.4451 21.0416V24.2347H28.2347V14.2774ZM27.9347 23.9347V14.2774C27.9347 14.1809 27.87 14.0541 27.7623 13.9725L19.9994 8.34539L12.2373 13.9308C12.1298 14.0124 12.0653 14.139 12.0653 14.2354V23.8927H16.2549V21.0416C16.2549 18.9853 17.9437 17.2965 20 17.2965C22.0563 17.2965 23.7451 18.9853 23.7451 21.0416V23.9347H27.9347Z"
          fill="#1F2E3C"
        />
      </g>
      <defs>
        <clipPath id="clip0_6:85">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
