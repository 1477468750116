import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Dialog
} from "@mui/material";
import { Box } from "@mui/system";

import React from "react";
import DCHeader from "./header";
import CustomizedTables from "./table";
import { aiData } from "./aiData";


function ModelDetails({ open, onClose }) {
  return (
    <Dialog
      sx={{ maxWidth: "none" }}
      open={open}
      onClose={onClose}
      maxWidth={false}
    >
      <div className="bikes-model-details" style={{background: '#222e3c'}}>
        <img src="/demo/It1.jpg" alt="" style={{width: 1000}} />
        <button onClick={onClose} className="standard-primary-button">
          Ok
        </button>
      </div>
    </Dialog>
  );
}

function UCIDetails({ open, onClose }) {
  return (
    <Dialog
      sx={{ maxWidth: "none" }}
      open={open}
      onClose={onClose}
      maxWidth={false}
    >
      <div className="bikes-model-details"  style={{background: '#222e3c'}}>
        <img src="/demo/UCI.jpg" alt="" style={{width: 800}} />
        <button onClick={onClose} className="standard-primary-button">
          Ok
        </button>
      </div>
    </Dialog>
  );
}

export default function DataCentric() {
  const [state, setState] = React.useState({
    step: 1,
  });

  const [page, setPage] = React.useState(5);

  const [showScore, setShowScore] = React.useState(false);
  const [showModel, setShowModel] = React.useState(false);
  const [showUci, setShowUci] = React.useState(false);

  const columns = [
    {
      name: "Row ID",
      key: "id",
    },
    {
      name: "Feature 1",
      key: "feature1",
    },
    {
      name: "Feature 2",
      key: "feature2",
    },
    {
      name: "Label",
      key: "label",
    },
    {
      name: "True Label",
      key: "trueLabel",
    },
  ];

  const handleRank = () => {
    setState({
      ...state,
      step: 2,
      batchSize: 10,
    });
  };

  const handleFix = () => {
    setState({
      ...state,
      step: 3,
    });
  };

  const selectedItem = React.useMemo(() => {
    return aiData.find(
      (_, index) =>
      index === (page - 1) * 10 + 9 || index === aiData.length - 1
    );
  }, [page]);

  return (
    <div className="data-centric-container">
      <Grid container>
        <Grid item xs={12}>
          <DCHeader
            step={state.step}
            setStep={(value) => setState({ ...state, step: value })}
          />
        </Grid>
      </Grid>
      {state.step === 1 && (
        <Grid container mt={2} spacing={4}>
          <Grid item xs={10}>
            <CustomizedTables
              data={[...aiData].sort((a, b) => a.id - b.id)}
              columns={columns}
            />
          </Grid>
          <Grid item xs={2}>
            <Card sx={{ p: 2, mb: 2 }}>
              <Typography color={"#14353C"} fontSize={18} fontWeight={700}>
                True Labels:{" "}
              </Typography>
              <Typography color={"#14353C"} fontSize={18} fontWeight={400}>
                {"0 -> Category A"}
              </Typography>
              <Typography color={"#14353C"} fontSize={18} fontWeight={400}>
                {"1 -> Category B"}
              </Typography>
              <Typography color={"#14353C"} fontSize={18} fontWeight={400}>
                {"2 -> Category C"}
              </Typography>
            </Card>
            <Button
              color={"primary"}
              fullWidth
              variant="contained"
              onClick={handleRank}
            >
              Rank by Importance
            </Button>
          </Grid>
        </Grid>
      )}

      {state.step === 2 && (
        <Grid container mt={2} spacing={4}>
          <Grid item xs={12}>
            <CustomizedTables data={aiData} columns={columns} />
          </Grid>
          <Grid item xs={12}>
            <Stack justifyContent={"flex-end"} direction="row">
              <Box sx={{ minWidth: 120, background: "white" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Set batch size
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state.batchSize}
                    label="Set batch size"
                    onChange={(e) =>
                      setState({ ...state, batchSize: e.target.value })
                    }
                  >
                    <MenuItem value={10}>10</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Button
                color={"primary"}
                variant="contained"
                onClick={handleFix}
                sx={{ ml: 2 }}
              >
                Fix Dirty Labels
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}

      {state.step === 3 && (
        <Grid container mt={2} spacing={4}>
          <Grid item xs={10}>
            <CustomizedTables
              data={aiData}
              columns={columns}
              pagination={true}
              page={page}
              setPage={(val) => {
                setShowScore(false);
                setPage(val);
              }}
              mark
              progress={selectedItem.progress}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              color={"primary"}
              fullWidth
              variant="contained"
              onClick={() => setShowScore(true)}
            >
              Compute Accuracy
            </Button>

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 2, background: "#789091" }}
              onClick={() => setShowModel(true)}
            >
              Show Accuracy Plot
            </Button>

            <Card sx={{ p: 2, mb: 9, mt: 2 }}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography color={"#14353C"} fontSize={18} fontWeight={700}>
                  MODULOS ACCURACY SCORE:
                </Typography>
                <Typography minWidth={80} textAlign="right" color={"#14353C"} fontSize={30} fontWeight={700}>
                  {showScore && Math.round(selectedItem.score * 100) + "%"}
                </Typography>
              </Stack>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography color={"#14353C"} fontSize={18} fontWeight={700}>
                  RANDOM ACCURACY SCORE:
                </Typography>

                <Typography minWidth={80} textAlign="right" color={"#14353C"} fontSize={30} fontWeight={700}>
                  {showScore && Math.round(selectedItem.random * 100) + "%"}
                </Typography>
              </Stack>
            </Card>
            <Button
              sx={{ background: "#789091" }}
              fullWidth
              variant="contained"
              onClick={() => setShowUci(true)}
            >
              Salary Use Case
            </Button>
          </Grid>
        </Grid>
      )}

      <ModelDetails open={showModel} onClose={() => setShowModel(false)} />
      <UCIDetails open={showUci} onClose={() => setShowUci(false)} />
    </div>
  );
}
