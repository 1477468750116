import { useState } from "react";
import { useModulosBody } from "../../context/ModulosBodyProvider";
import { BiChevronLeft } from "react-icons/bi";
import ModelDetails from "./ModelDetails";

export default function DemoViewHeader({
  name,
  thirdComponent: ThirdComponent,
  image,
  onChange
}) {
  let [, setModulosBody] = useModulosBody();
  let [modelOpen, setModelOpen] = useState(false);
  function onClickBack() {
    setModulosBody((existingBody) => {
      return {
        ...existingBody,
        activeDemo: null,
      };
    });
  }
  return (
    <div className="demo-item-header">
      <div className="item-back-button" onClick={onClickBack}>
        <BiChevronLeft size={35} />
        <span className="item-text">Back</span>
      </div>
      <p>{name}</p>
      {ThirdComponent === true ? (
        <>
          <div
            className="model-details-trigger"
            onClick={() => setModelOpen(true)}
          >
            <img src="/info.png" alt="" />
            <span> Model Details</span>
          </div>
          <ModelDetails
            open={modelOpen}
            onClose={() => setModelOpen(false)}
            image={image}
          ></ModelDetails>
        </>
      ) : ThirdComponent ? (
        <ThirdComponent onChange={onChange} />
      ) : null}
    </div>
  );
}
