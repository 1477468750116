import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
// import { Step } from "@mui/material";

export default function DCHeader({ step, setStep }) {
  const labels = ["INPUT DATASET", "RANKING BY IMPORTANCE", "FIX DIRTY LABELS"];
  return (
    <div className="header-container">
      <ProgressBar
        percent={step === 1 ? 10 : step === 2 ? 50 : step === 3 ? 100 : 0}
      >
        {labels.map((label, count) => (
          <Step style={{ left: 0 }}>
            {({ accomplished, index }) => (
              <>
                <div className="label-header">{`STEP ${count + 1}`}</div>
                <div className={`indexedStep`} onClick={() => setStep(index + 1)}>
                  {accomplished && <div className="accom-circle"></div>}
                </div>
                <div className="label-footer">{label}</div>
              </>
            )}
          </Step>
        ))}
      </ProgressBar>
    </div>
  );
}
