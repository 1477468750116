import React from "react";
import PriceIndicatorBar from "./PriceIndicatorBar";

function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

export default function PropertyItem({ property, index }) {
  const { img, name, bedroom, baths, built, location, price, pricing } =
    property;
  return (
    <div className="property-item">
      <img
        className="property-image"
        src={`/demo/properties/${img}.png`}
        alt=""
      />
      <h2 className="name">{name}</h2>
      <div className="features">
        <div className="bedroom">
          <img src="/demo/properties/bed.svg" alt="" />
          {bedroom} <span className="label"> bedroom</span>
        </div>
        <div className="baths">
          <img src="/demo/properties/bath.svg" alt="" />
          {baths} <span className="label"> baths</span>
        </div>
        <div className="built-year">
          <img src="/demo/properties/built.svg" alt="" />
          <span className="label"> Built in</span> {built}
        </div>
      </div>
      <div className="location">{location}</div>
      <div className="price">USD {numberWithCommas(price)}</div>
      <PriceIndicatorBar
        markerLeft={property.marker}
        pricing={pricing}
        price={price}
        index={index}
      />
    </div>
  );
}
