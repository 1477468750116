import React, { useContext } from "react";
import { useState } from "react";

const ModulosBodyContext = React.createContext();
let setModulosBody;

export function useModulosBody() {
  return [useContext(ModulosBodyContext), setModulosBody];
}

export default function ModulosBodyProvider({ children }) {
  let modulosBody;
  [modulosBody, setModulosBody] = useState({
    activeMainView: "HomePage",
    activeDemo: null,
  });

  return (
    <ModulosBodyContext.Provider value={modulosBody}>
      {children}
    </ModulosBodyContext.Provider>
  );
}
