import { Dialog } from "@mui/material";

export default function ModelDetails({ open, onClose, image, moreInfo }) {
  return (
    <div id="model-details">
      <Dialog
        maxWidth={false}
        sx={{ maxWidth: "none", overflowX: 'hidden' }}
        open={open}
        onClose={onClose}
      >
        <div className="model-chart-container">
          <h1>MODEL DETAILS</h1>
          <div id="model-chart">
            {/* <h3>Permutation Feature Importance</h3> */}
            <img src={image} style={{ width: "100%" }} alt="model-info" />
          </div>
          <div className="footer-items">
            <div onClick={() => onClose()} className="button">
              OK
            </div>
            {moreInfo && (
              <div className="more-info">
              <img src={moreInfo}  alt="more-info"/>
            </div>
            )}
            
          </div>
        </div>
      </Dialog>
    </div>
  );
}
