import React from "react";
import PropertyItem from "./PropertyItem";
import DemoViewHeader from "./DemoViewHeader";

let properties = [
  {
    name: "Townhouse",
    bedroom: 3,
    baths: 2,
    built: 2001,
    location: "Beverly Hills",
    price: 1200321,
    img: "Townhouse",
    pricing: "Overpriced",
    marker: 75,
  },
  {
    name: "Flat",
    bedroom: 4,
    baths: 1,
    built: 2001,
    location: "LA Downtown",
    price: 870000,
    img: "Flat-indoor",
    pricing: "Slightly Underpriced",
    marker: 13,
  },
  {
    name: "Flat",
    bedroom: 3,
    baths: 2,
    built: 2001,
    location: "Santa Barbara",
    price: 1500000,
    img: "Flat-outdoor",
    pricing: "Rightly Priced",
    marker: 45,
  },
  {
    name: "House",
    bedroom: 5,
    baths: 3,
    built: 2001,
    location: "Beverly Hills",
    price: 230000,
    img: "House",
    pricing: "Overpriced",
    marker: 75,
  },

  {
    name: "House",
    bedroom: 5,
    baths: 3,
    built: 2001,
    location: "Malibu",
    price: 2500321,
    img: "Solar-house",
    pricing: "Overpriced",
    marker: 92,
  },
  {
    name: "Flat",
    bedroom: 2,
    baths: 1,
    built: 2001,
    location: "Santa Monica",
    price: 630000,
    img: "Studio",
    pricing: "Rightly priced",
    marker: 50,
  },
];

export default function PropertyPriceIndicator() {
  return (
    <>
      <DemoViewHeader
        name="Use Case: Property Price indicator"
        // thirdComponent={null}
      />
      <div id="property-price-indicator">
        {properties.map((property, index) => {
          return (
            <PropertyItem
              key={property.img}
              index={index}
              property={property}
            />
          );
        })}
      </div>
    </>
  );
}
