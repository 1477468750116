import React from "react";
import LeftPanel from "./LeftPanel/LeftPanel";
import MainView from "./MainView/MainView";
import { useCookies } from "react-cookie";
import LoginPage from "./MainView/Demo/Login";

export default function Body() {
  const [cookies] = useCookies(["username"]);

  return (
    <>
      {cookies.username ? (
        <div id="modulos-body">
          <LeftPanel></LeftPanel>
          <MainView />
        </div>
      ) : (
        <LoginPage />
      )}
    </>
  );
}
