import { useBackdrop } from "components/context/BackdropProvider";
import React, { useState } from "react";
import DemoViewHeader from "./DemoViewHeader";

const predictArray = [
  {
    trueValue: "Early-Type",
    predictValue: "Early-Type",
    image: 1,
  },
  {
    trueValue: "Late-Type",
    predictValue: "Late-Type",
    image: 2,
  },
  {
    trueValue: "Early-Type",
    predictValue: "Early-Type",
    image: 3,
  },
  {
    trueValue: "Late-Type",
    predictValue: "Late-Type",
    image: 4,
  },
  {
    trueValue: "Early-Type",
    predictValue: "Early-Type",
    image: 5,
  },
  {
    trueValue: "Early-Type",
    predictValue: "Early-Type",
    image: 6,
  },
  {
    trueValue: "Early-Type",
    predictValue: "Early-Type",
    image: 7,
  },
  {
    trueValue: "Early-Type",
    predictValue: "Early-Type",
    image: 8,
  },
  {
    trueValue: "Early-Type",
    predictValue: "Early-Type",
    image: 9,
  },
  {
    trueValue: "Early-Type",
    predictValue: "Early-Type",
    image: 10,
  },
  {
    trueValue: "Early-Type",
    predictValue: "Late-Type",
    image: 11,
  },
  {
    trueValue: "Late-Type",
    predictValue: "Early-Type",
    image: 12,
  },
];

export default function GalaxyTypeID() {
  const [selectItem, setSelecteItem] = useState(null);
  const [trueAnswer, setTrueAnswer] = useState(null);

  const { fetching } = useBackdrop();

  const handleImageClick = (index) => {
    const random = 2000; // getRandomInt(2, 5) * 1000; // random time;
    setSelecteItem(null);
    setTrueAnswer(null);
    fetching(true);
    setTimeout(() => {
      fetching(false);
      setSelecteItem(predictArray.find((item) => item.image === index));
    }, random);
  };

  const handleTrueAnswer = () => {
    const random = 2000;// getRandomInt(2, 5) * 1000;
    setTrueAnswer(null);
    fetching(true);
    setTimeout(() => {
      fetching(false);
      setTrueAnswer(selectItem.predictValue === selectItem.trueValue);
    }, random);
  };
  return (
    <>
      <DemoViewHeader name="Galaxy Type" />
      <div id="galaxy-type-id">
        {predictArray.map((num, index) => {
          return (
            <div
              className="type-image"
              key={`${index}-image`}
              onClick={() => handleImageClick(index + 1)}
            >
              <img src={`/demo/galaxy-type/1 (${index + 1}).png`} alt="" />
            </div>
          );
        })}
      </div>
      {selectItem && (
        <div className="galaxy">
          <div className="galaxy-result">
            Prediction: {selectItem.predictValue}
          </div>
          {trueAnswer !== null && (
            <div className="galaxy-predict">
              {trueAnswer ? "Correct" : "Wrong"}
            </div>
          )}
          <div className="galaxy-button" onClick={handleTrueAnswer}>
            True answer
          </div>
        </div>
      )}
    </>
  );
}
