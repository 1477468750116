import React from "react";
import { useModulosBody } from "../../context/ModulosBodyProvider";
import ReactHtmlParser from "react-html-parser";

export default function DemoItem({ item }) {
  let [, setModulosBody] = useModulosBody();
  function handleClick() {
    setModulosBody((existingBody) => {
      return {
        ...existingBody,
        activeDemo: item.key,
      };
    });
  }
  let { svg: Svg } = item;
  return (
    <div className="demo-item" onClick={handleClick}>
      <div>
        <Svg/>

        <h2>{ReactHtmlParser(item.title)}</h2>
        <h4 className="demo-subtitle">{item.subtitle}</h4>
        <div className="background-image">
          <Svg/>
        </div>
      </div>
    </div>
  );
}
