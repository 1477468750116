import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import TablePagination from "@mui/material/TablePagination";
import { useState } from "react";
// import Pagination from "@mui/material/Pagination";

import DemoViewHeader from "./DemoViewHeader";
import CovidData from "util/covid-data";
import moment from "moment";
import { Dialog } from "@mui/material";
// import covidImage from "../../../assets/images/covid-details.svg";
import covidImage from "../../../assets/images/Covid hospitalization graph.svg";
const dateFormat = "YYYY-MM-DD";

function createData(
  date,
  holiday,
  weekday,
  workingDay,
  weatherSituation,
  humidityPercent,
  avgTempC,
  windSpeedKM,
  casualUsers,
  registeredUsers,
  totalUsers
) {
  return {
    date,
    holiday,
    weekday,
    workingDay,
    weatherSituation,
    humidityPercent,
    avgTempC,
    windSpeedKM,
    casualUsers,
    registeredUsers,
    totalUsers,
  };
}

function DatePicker() {
  return (
    <div className="header-descriptions">
      <span className="date-label">
        This predictions are for educational purpose only. Modulos does not
        guarantee
      </span>
      <span className="date-label">
        that the predictions generated are reflecting the actual situation.
      </span>
    </div>
  );
}

const rows = [];

for (let i = 0; i <= 33; i++) {
  rows.push(createData("2012-11-23", 0, 5, 5, i));
}

export default function CovidDetails() {
  // const [tablePage, setTablePage] = useState(0);

  const [predictDate, setPredictDate] = useState("2012/12/02");
  const [predictValue] = useState(null);
  const [alert, setAlert] = useState(false);

  // let tableRows = CovidData.slice(tablePage * 10, tablePage * 10 + 10);
  let tableRows = CovidData;

  const handleDateChange = (date) => {
    setPredictDate(date);
  };

  return (
    <>
      <DemoViewHeader
        name="Covid Hospitalizations"
        thirdComponent={DatePicker}
        onChange={handleDateChange}
      />

      <div id="covid-details">
        <div className="table-cont">
          <TableContainer className="covid-details-table" component={Paper}>
            <Table sx={{ width: "100%" }}>
              <TableHead>
                <TableRow
                  className="table-head-row"
                  sx={{
                    height: 81,
                    backgroundColor: "#BFD7D0",
                  }}
                >
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Reported New Covid Cases</TableCell>
                  <TableCell align="right">
                    Reported New Hospitalizations
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows.map((row, index) => {
                  let backgroundColor = index % 2 === 0 ? "#F2F2F2" : "#F9F9F9";
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        backgroundColor,
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.datum}
                      </TableCell>
                      <TableCell align="right">{row.new_cases}</TableCell>
                      <TableCell align="right">{row.new_hosp}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="covid-actions">
            <span div="no-display"></span>
            {/* <Pagination
              className="table-pagination"
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "30px",
                li: {
                  margin: "3px",
                  // padding: 0,
                  backgroundColor: "#BFD7D0",
                  borderRadius: "4px",
                },
                "li > *": {
                  color: "white",
                  fontWeight: "bold",
                  width: "100%",
                  padding: "0",
                  margin: 0,
                  borderRadius: "4px",
                  textAlign: "center",
                },
                "li:hover >*": {
                  backgroundColor: "#304f4e !important",
                },
                "li .MuiButtonBase-root ": {
                  width: "100%",
                },

                "li > *.Mui-selected": {
                  backgroundColor: "#416C6A",
                  color: "white",
                },
                "li:hover > *.Mui-selected": {
                  backgroundColor: "#304f4e",
                  color: "white",
                },
              }}
              onChange={(e, page) => setTablePage(page - 1)}
              count={3}
            /> */}
          </div>
        </div>
        <div className="table-cont">
          {/* <img src={covidImage} alt="covid-details" /> */}
          <img src={covidImage} alt="covid-details" />
        </div>
      </div>
      <Dialog onClose={() => setAlert(false)} open={alert}>
        <div className="bikes-predict-result">
          <h2>Predicted demand for:</h2>
          <h3>{moment(predictDate).format(dateFormat)}</h3>
          <div>
            <h1 className="result">{predictValue}</h1>
            <h3 className="result">rented bicycles</h3>
          </div>
          <div className="use-case-button" onClick={() => setAlert(false)}>
            Ok
          </div>
        </div>
      </Dialog>
    </>
  );
}
