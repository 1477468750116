export const aiData = [
    {
      "id": 105,
      "feature1": 4.9,
      "feature2": 2.5,
      "trueLabel": 2,
      "label": 0,
      "score": 0.210526316,
      "random": 0.210526316,
      "progress": 0
    },
    {
      "id": 40,
      "feature1": 6.7,
      "feature2": 3,
      "trueLabel": 2,
      "label": 0,
      "score": 0.210526316,
      "random": 0.210526316,
      "progress": 0.008928571
    },
    {
      "id": 90,
      "feature1": 6.8,
      "feature2": 3,
      "trueLabel": 2,
      "label": 0,
      "score": 0.210526316,
      "random": 0.210526316,
      "progress": 0.017857143
    },
    {
      "id": 44,
      "feature1": 6.2,
      "feature2": 3.4,
      "trueLabel": 2,
      "label": 0,
      "score": 0.210526316,
      "random": 0.210526316,
      "progress": 0.026785714
    },
    {
      "id": 74,
      "feature1": 6.1,
      "feature2": 2.6,
      "trueLabel": 2,
      "label": 0,
      "score": 0.210526316,
      "random": 0.210526316,
      "progress": 0.035714286
    },
    {
      "id": 49,
      "feature1": 5.8,
      "feature2": 2.7,
      "trueLabel": 2,
      "label": 0,
      "score": 0.210526316,
      "random": 0.210526316,
      "progress": 0.044642857
    },
    {
      "id": 18,
      "feature1": 5.8,
      "feature2": 2.8,
      "trueLabel": 2,
      "label": 0,
      "score": 0.210526316,
      "random": 0.210526316,
      "progress": 0.053571429
    },
    {
      "id": 59,
      "feature1": 6,
      "feature2": 3,
      "trueLabel": 2,
      "label": 0,
      "score": 0.184210526,
      "random": 0.210526316,
      "progress": 0.0625
    },
    {
      "id": 100,
      "feature1": 5.8,
      "feature2": 2.7,
      "trueLabel": 2,
      "label": 0,
      "score": 0.184210526,
      "random": 0.210526316,
      "progress": 0.071428571
    },
    {
      "id": 68,
      "feature1": 5.9,
      "feature2": 3,
      "trueLabel": 2,
      "label": 0,
      "score": 0.184210526,
      "random": 0.210526316,
      "progress": 0.080357143
    },
    {
      "id": 85,
      "feature1": 6.3,
      "feature2": 2.5,
      "trueLabel": 2,
      "label": 0,
      "score": 0.184210526,
      "random": 0.210526316,
      "progress": 0.089285714
    },
    {
      "id": 51,
      "feature1": 5.7,
      "feature2": 2.5,
      "trueLabel": 2,
      "label": 0,
      "score": 0.184210526,
      "random": 0.210526316,
      "progress": 0.098214286
    },
    {
      "id": 24,
      "feature1": 6.3,
      "feature2": 2.7,
      "trueLabel": 2,
      "label": 0,
      "score": 0.184210526,
      "random": 0.210526316,
      "progress": 0.107142857
    },
    {
      "id": 95,
      "feature1": 6.1,
      "feature2": 3,
      "trueLabel": 2,
      "label": 0,
      "score": 0.184210526,
      "random": 0.210526316,
      "progress": 0.116071429
    },
    {
      "id": 33,
      "feature1": 5.6,
      "feature2": 2.8,
      "trueLabel": 2,
      "label": 0,
      "score": 0.184210526,
      "random": 0.210526316,
      "progress": 0.125
    },
    {
      "id": 56,
      "feature1": 6.7,
      "feature2": 3.1,
      "trueLabel": 2,
      "label": 0,
      "score": 0.184210526,
      "random": 0.210526316,
      "progress": 0.133928571
    },
    {
      "id": 21,
      "feature1": 6.4,
      "feature2": 2.8,
      "trueLabel": 2,
      "label": 0,
      "score": 0.184210526,
      "random": 0.210526316,
      "progress": 0.142857143
    },
    {
      "id": 99,
      "feature1": 6.4,
      "feature2": 2.8,
      "trueLabel": 2,
      "label": 0,
      "score": 0.184210526,
      "random": 0.184210526,
      "progress": 0.151785714
    },
    {
      "id": 0,
      "feature1": 6.3,
      "feature2": 2.9,
      "trueLabel": 2,
      "label": 0,
      "score": 0.210526316,
      "random": 0.184210526,
      "progress": 0.160714286
    },
    {
      "id": 82,
      "feature1": 5.4,
      "feature2": 3.4,
      "trueLabel": 0,
      "label": 2,
      "score": 0.210526316,
      "random": 0.184210526,
      "progress": 0.169642857
    },
    {
      "id": 2,
      "feature1": 5.4,
      "feature2": 3.4,
      "trueLabel": 0,
      "label": 2,
      "score": 0.210526316,
      "random": 0.184210526,
      "progress": 0.178571429
    },
    {
      "id": 4,
      "feature1": 5.5,
      "feature2": 3.5,
      "trueLabel": 0,
      "label": 2,
      "score": 0.210526316,
      "random": 0.184210526,
      "progress": 0.1875
    },
    {
      "id": 63,
      "feature1": 5.2,
      "feature2": 3.5,
      "trueLabel": 0,
      "label": 2,
      "score": 0.184210526,
      "random": 0.184210526,
      "progress": 0.196428571
    },
    {
      "id": 77,
      "feature1": 5,
      "feature2": 3,
      "trueLabel": 0,
      "label": 2,
      "score": 0.184210526,
      "random": 0.184210526,
      "progress": 0.205357143
    },
    {
      "id": 25,
      "feature1": 6.5,
      "feature2": 3,
      "trueLabel": 2,
      "label": 0,
      "score": 0.184210526,
      "random": 0.210526316,
      "progress": 0.214285714
    },
    {
      "id": 16,
      "feature1": 5.3,
      "feature2": 3.7,
      "trueLabel": 0,
      "label": 2,
      "score": 0.210526316,
      "random": 0.210526316,
      "progress": 0.223214286
    },
    {
      "id": 47,
      "feature1": 5.1,
      "feature2": 3.7,
      "trueLabel": 0,
      "label": 2,
      "score": 0.210526316,
      "random": 0.210526316,
      "progress": 0.232142857
    },
    {
      "id": 31,
      "feature1": 5.1,
      "feature2": 3.5,
      "trueLabel": 0,
      "label": 2,
      "score": 0.236842105,
      "random": 0.210526316,
      "progress": 0.241071429
    },
    {
      "id": 111,
      "feature1": 5.1,
      "feature2": 3.5,
      "trueLabel": 0,
      "label": 2,
      "score": 0.236842105,
      "random": 0.210526316,
      "progress": 0.25
    },
    {
      "id": 14,
      "feature1": 5.1,
      "feature2": 3.8,
      "trueLabel": 0,
      "label": 2,
      "score": 0.210526316,
      "random": 0.210526316,
      "progress": 0.258928571
    },
    {
      "id": 71,
      "feature1": 5.4,
      "feature2": 3.9,
      "trueLabel": 0,
      "label": 2,
      "score": 0.263157895,
      "random": 0.210526316,
      "progress": 0.267857143
    },
    {
      "id": 37,
      "feature1": 5.4,
      "feature2": 3.9,
      "trueLabel": 0,
      "label": 2,
      "score": 0.289473684,
      "random": 0.210526316,
      "progress": 0.276785714
    },
    {
      "id": 66,
      "feature1": 6.3,
      "feature2": 3.4,
      "trueLabel": 2,
      "label": 0,
      "score": 0.315789474,
      "random": 0.236842105,
      "progress": 0.285714286
    },
    {
      "id": 93,
      "feature1": 5,
      "feature2": 3.5,
      "trueLabel": 0,
      "label": 2,
      "score": 0.289473684,
      "random": 0.236842105,
      "progress": 0.294642857
    },
    {
      "id": 67,
      "feature1": 5,
      "feature2": 3.5,
      "trueLabel": 0,
      "label": 2,
      "score": 0.315789474,
      "random": 0.210526316,
      "progress": 0.303571429
    },
    {
      "id": 19,
      "feature1": 5.1,
      "feature2": 3.3,
      "trueLabel": 0,
      "label": 2,
      "score": 0.368421053,
      "random": 0.210526316,
      "progress": 0.3125
    },
    {
      "id": 81,
      "feature1": 4.9,
      "feature2": 3,
      "trueLabel": 0,
      "label": 2,
      "score": 0.368421053,
      "random": 0.210526316,
      "progress": 0.321428571
    },
    {
      "id": 69,
      "feature1": 5.5,
      "feature2": 4.2,
      "trueLabel": 0,
      "label": 2,
      "score": 0.368421053,
      "random": 0.210526316,
      "progress": 0.330357143
    },
    {
      "id": 88,
      "feature1": 6.4,
      "feature2": 3.1,
      "trueLabel": 2,
      "label": 0,
      "score": 0.368421053,
      "random": 0.210526316,
      "progress": 0.339285714
    },
    {
      "id": 65,
      "feature1": 4.9,
      "feature2": 3.1,
      "trueLabel": 0,
      "label": 2,
      "score": 0.368421053,
      "random": 0.210526316,
      "progress": 0.348214286
    },
    {
      "id": 94,
      "feature1": 5,
      "feature2": 3.2,
      "trueLabel": 0,
      "label": 2,
      "score": 0.394736842,
      "random": 0.210526316,
      "progress": 0.357142857
    },
    {
      "id": 75,
      "feature1": 5,
      "feature2": 3.3,
      "trueLabel": 0,
      "label": 2,
      "score": 0.421052632,
      "random": 0.210526316,
      "progress": 0.366071429
    },
    {
      "id": 23,
      "feature1": 4.8,
      "feature2": 3,
      "trueLabel": 0,
      "label": 2,
      "score": 0.473684211,
      "random": 0.210526316,
      "progress": 0.375
    },
    {
      "id": 22,
      "feature1": 5.8,
      "feature2": 4,
      "trueLabel": 0,
      "label": 2,
      "score": 0.5,
      "random": 0.210526316,
      "progress": 0.383928571
    },
    {
      "id": 54,
      "feature1": 4.8,
      "feature2": 3.4,
      "trueLabel": 0,
      "label": 2,
      "score": 0.552631579,
      "random": 0.210526316,
      "progress": 0.392857143
    },
    {
      "id": 10,
      "feature1": 4.8,
      "feature2": 3.4,
      "trueLabel": 0,
      "label": 2,
      "score": 0.552631579,
      "random": 0.210526316,
      "progress": 0.401785714
    },
    {
      "id": 6,
      "feature1": 4.8,
      "feature2": 3.1,
      "trueLabel": 0,
      "label": 2,
      "score": 0.552631579,
      "random": 0.210526316,
      "progress": 0.410714286
    },
    {
      "id": 76,
      "feature1": 6.8,
      "feature2": 3.2,
      "trueLabel": 2,
      "label": 0,
      "score": 0.552631579,
      "random": 0.210526316,
      "progress": 0.419642857
    },
    {
      "id": 52,
      "feature1": 4.6,
      "feature2": 3.6,
      "trueLabel": 0,
      "label": 2,
      "score": 0.552631579,
      "random": 0.210526316,
      "progress": 0.428571429
    },
    {
      "id": 42,
      "feature1": 4.7,
      "feature2": 3.2,
      "trueLabel": 0,
      "label": 2,
      "score": 0.552631579,
      "random": 0.263157895,
      "progress": 0.4375
    },
    {
      "id": 1,
      "feature1": 4.7,
      "feature2": 3.2,
      "trueLabel": 0,
      "label": 2,
      "score": 0.552631579,
      "random": 0.263157895,
      "progress": 0.446428571
    },
    {
      "id": 79,
      "feature1": 6.9,
      "feature2": 3.1,
      "trueLabel": 2,
      "label": 0,
      "score": 0.605263158,
      "random": 0.263157895,
      "progress": 0.455357143
    },
    {
      "id": 8,
      "feature1": 4.6,
      "feature2": 3.4,
      "trueLabel": 0,
      "label": 2,
      "score": 0.578947368,
      "random": 0.263157895,
      "progress": 0.464285714
    },
    {
      "id": 9,
      "feature1": 4.6,
      "feature2": 3.2,
      "trueLabel": 0,
      "label": 2,
      "score": 0.605263158,
      "random": 0.263157895,
      "progress": 0.473214286
    },
    {
      "id": 58,
      "feature1": 4.6,
      "feature2": 3.1,
      "trueLabel": 0,
      "label": 2,
      "score": 0.631578947,
      "random": 0.263157895,
      "progress": 0.482142857
    },
    {
      "id": 103,
      "feature1": 6.4,
      "feature2": 3.2,
      "trueLabel": 2,
      "label": 0,
      "score": 0.605263158,
      "random": 0.263157895,
      "progress": 0.491071429
    },
    {
      "id": 20,
      "feature1": 6.9,
      "feature2": 3.2,
      "trueLabel": 2,
      "label": 0,
      "score": 0.631578947,
      "random": 0.263157895,
      "progress": 0.5
    },
    {
      "id": 84,
      "feature1": 4.4,
      "feature2": 3.2,
      "trueLabel": 0,
      "label": 2,
      "score": 0.657894737,
      "random": 0.289473684,
      "progress": 0.508928571
    },
    {
      "id": 109,
      "feature1": 7.1,
      "feature2": 3,
      "trueLabel": 2,
      "label": 0,
      "score": 0.631578947,
      "random": 0.263157895,
      "progress": 0.517857143
    },
    {
      "id": 26,
      "feature1": 4.4,
      "feature2": 3,
      "trueLabel": 0,
      "label": 2,
      "score": 0.684210526,
      "random": 0.263157895,
      "progress": 0.526785714
    },
    {
      "id": 61,
      "feature1": 6.3,
      "feature2": 3.3,
      "trueLabel": 2,
      "label": 0,
      "score": 0.657894737,
      "random": 0.289473684,
      "progress": 0.535714286
    },
    {
      "id": 17,
      "feature1": 4.4,
      "feature2": 2.9,
      "trueLabel": 0,
      "label": 2,
      "score": 0.657894737,
      "random": 0.289473684,
      "progress": 0.544642857
    },
    {
      "id": 15,
      "feature1": 4.3,
      "feature2": 3,
      "trueLabel": 0,
      "label": 2,
      "score": 0.657894737,
      "random": 0.289473684,
      "progress": 0.553571429
    },
    {
      "id": 38,
      "feature1": 6.7,
      "feature2": 3.3,
      "trueLabel": 2,
      "label": 0,
      "score": 0.657894737,
      "random": 0.236842105,
      "progress": 0.5625
    },
    {
      "id": 27,
      "feature1": 7.3,
      "feature2": 2.9,
      "trueLabel": 2,
      "label": 0,
      "score": 0.763157895,
      "random": 0.263157895,
      "progress": 0.571428571
    },
    {
      "id": 83,
      "feature1": 7.2,
      "feature2": 3.2,
      "trueLabel": 2,
      "label": 0,
      "score": 0.789473684,
      "random": 0.263157895,
      "progress": 0.580357143
    },
    {
      "id": 86,
      "feature1": 7.4,
      "feature2": 2.8,
      "trueLabel": 2,
      "label": 0,
      "score": 0.789473684,
      "random": 0.263157895,
      "progress": 0.589285714
    },
    {
      "id": 72,
      "feature1": 7.6,
      "feature2": 3,
      "trueLabel": 2,
      "label": 0,
      "score": 0.789473684,
      "random": 0.342105263,
      "progress": 0.598214286
    },
    {
      "id": 89,
      "feature1": 7.2,
      "feature2": 3.6,
      "trueLabel": 2,
      "label": 0,
      "score": 0.789473684,
      "random": 0.342105263,
      "progress": 0.607142857
    },
    {
      "id": 39,
      "feature1": 4.5,
      "feature2": 2.3,
      "trueLabel": 0,
      "label": 2,
      "score": 0.789473684,
      "random": 0.342105263,
      "progress": 0.616071429
    },
    {
      "id": 102,
      "feature1": 7.7,
      "feature2": 3,
      "trueLabel": 2,
      "label": 0,
      "score": 0.789473684,
      "random": 0.368421053,
      "progress": 0.625
    },
    {
      "id": 70,
      "feature1": 7.7,
      "feature2": 2.6,
      "trueLabel": 2,
      "label": 0,
      "score": 0.789473684,
      "random": 0.368421053,
      "progress": 0.633928571
    },
    {
      "id": 55,
      "feature1": 7.9,
      "feature2": 3.8,
      "trueLabel": 2,
      "label": 0,
      "score": 0.815789474,
      "random": 0.368421053,
      "progress": 0.642857143
    },
    {
      "id": 46,
      "feature1": 5.2,
      "feature2": 2.7,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.447368421,
      "progress": 0.651785714
    },
    {
      "id": 29,
      "feature1": 7,
      "feature2": 3.2,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.605263158,
      "progress": 0.660714286
    },
    {
      "id": 48,
      "feature1": 4.9,
      "feature2": 2.4,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.631578947,
      "progress": 0.669642857
    },
    {
      "id": 36,
      "feature1": 5,
      "feature2": 2,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.736842105,
      "progress": 0.678571429
    },
    {
      "id": 45,
      "feature1": 5,
      "feature2": 2.3,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.6875
    },
    {
      "id": 101,
      "feature1": 6.4,
      "feature2": 3.2,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.696428571
    },
    {
      "id": 7,
      "feature1": 5.5,
      "feature2": 2.5,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.705357143
    },
    {
      "id": 28,
      "feature1": 5.5,
      "feature2": 2.3,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.714285714
    },
    {
      "id": 12,
      "feature1": 5.5,
      "feature2": 2.4,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.723214286
    },
    {
      "id": 110,
      "feature1": 5.5,
      "feature2": 2.4,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.732142857
    },
    {
      "id": 62,
      "feature1": 5.5,
      "feature2": 2.6,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.741071429
    },
    {
      "id": 60,
      "feature1": 5.6,
      "feature2": 2.5,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.75
    },
    {
      "id": 73,
      "feature1": 5.4,
      "feature2": 3,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.758928571
    },
    {
      "id": 13,
      "feature1": 6.4,
      "feature2": 2.9,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.767857143
    },
    {
      "id": 32,
      "feature1": 6.9,
      "feature2": 3.1,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.776785714
    },
    {
      "id": 57,
      "feature1": 5.6,
      "feature2": 2.7,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.785714286
    },
    {
      "id": 34,
      "feature1": 6.3,
      "feature2": 3.3,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.794642857
    },
    {
      "id": 87,
      "feature1": 6.5,
      "feature2": 2.8,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.803571429
    },
    {
      "id": 78,
      "feature1": 5.6,
      "feature2": 3,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.8125
    },
    {
      "id": 106,
      "feature1": 6.7,
      "feature2": 3.1,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.821428571
    },
    {
      "id": 80,
      "feature1": 6.6,
      "feature2": 3,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.830357143
    },
    {
      "id": 30,
      "feature1": 6.6,
      "feature2": 2.9,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.839285714
    },
    {
      "id": 97,
      "feature1": 5.9,
      "feature2": 3,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.763157895,
      "progress": 0.848214286
    },
    {
      "id": 53,
      "feature1": 6.3,
      "feature2": 2.5,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.815789474,
      "progress": 0.857142857
    },
    {
      "id": 41,
      "feature1": 5.6,
      "feature2": 2.9,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.815789474,
      "progress": 0.866071429
    },
    {
      "id": 11,
      "feature1": 5.7,
      "feature2": 3,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.815789474,
      "progress": 0.875
    },
    {
      "id": 3,
      "feature1": 5.7,
      "feature2": 2.8,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.815789474,
      "progress": 0.883928571
    },
    {
      "id": 35,
      "feature1": 5.7,
      "feature2": 2.8,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.789473684,
      "progress": 0.892857143
    },
    {
      "id": 43,
      "feature1": 6.7,
      "feature2": 3.1,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.789473684,
      "progress": 0.901785714
    },
    {
      "id": 96,
      "feature1": 5.9,
      "feature2": 3.2,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.789473684,
      "progress": 0.910714286
    },
    {
      "id": 98,
      "feature1": 5.7,
      "feature2": 2.6,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.789473684,
      "progress": 0.919642857
    },
    {
      "id": 91,
      "feature1": 6.1,
      "feature2": 3,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.789473684,
      "progress": 0.928571429
    },
    {
      "id": 92,
      "feature1": 6.3,
      "feature2": 2.3,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.789473684,
      "progress": 0.9375
    },
    {
      "id": 5,
      "feature1": 6,
      "feature2": 2.2,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.789473684,
      "progress": 0.946428571
    },
    {
      "id": 107,
      "feature1": 6.2,
      "feature2": 2.9,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.789473684,
      "progress": 0.955357143
    },
    {
      "id": 104,
      "feature1": 5.8,
      "feature2": 2.7,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.789473684,
      "progress": 0.964285714
    },
    {
      "id": 50,
      "feature1": 5.8,
      "feature2": 2.7,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.789473684,
      "progress": 0.973214286
    },
    {
      "id": 108,
      "feature1": 6.1,
      "feature2": 2.9,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.789473684,
      "progress": 0.982142857
    },
    {
      "id": 64,
      "feature1": 6.1,
      "feature2": 2.8,
      "trueLabel": 1,
      "label": 1,
      "score": 0.815789474,
      "random": 0.815789474,
      "progress": 0.991071429
    }
  ]