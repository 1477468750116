
import ListItem from "./ListItem";
let items = [
  {
    img: '/VectorData.svg',
    name: 'Data-Centric AI'
  },
  {
    img: "/demo.svg",
    name: "Use cases",
  },
  {
    img: "/globe.svg",
    name: "Modulos.ai",
    link: 'https://www.modulos.ai/'
  },
  {
    img: "/computer.svg",
    name: "AutoML Platform",
    link: 'https://demo.modulos.ai/'
  },
  {
    img: "/document.svg",
    name: "Resources",
  },
  {
    img: '/VectorVideo.svg',
    name: 'Video'
  },
  {
    img: '/Vision.svg',
    name: 'Vision'
  }
];

export default function LeftPanel() {
  return (
    <div id="left-panel">
      {items.map((item) => {
        return <ListItem key={item.name} img={item.img} name={item.name} link={item.link} />;
      })}
    </div>
  );
}
