import MuiRadio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import MuiSlider from "@mui/material/Slider";
import Dialog from "@mui/material/Dialog";
import ModelDetails from "./ModelDetails";
import { styled } from "@mui/material/styles";
import DemoViewHeader from "./DemoViewHeader";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { Typography } from "@mui/material";

import Formula from "../../../assets/images/formula.png";
// import ModelInfo from "../../../assets/images/feature_importance (2).png";
import ModelInfo from "../../../assets/images/churn predictor graph.jpeg";
import { useBackdrop } from "components/context/BackdropProvider";

const Radio = styled(MuiRadio)(() => ({
  color: "#7AA89C",
  paddingLeft: 0,
  "&.Mui-checked": {
    color: "#7AA89C",
  },
}));

const Select = styled(MuiSelect)(() => ({
  borderRadius: 4,
  height: 40,
  "& >*": {
    border: "none",
  },
}));
const Slider = styled(MuiSlider)(() => ({
  color: "white",
  "& .MuiSlider-thumb": {
    border: "2px solid #7AA89C",
  },
  "& .MuiSlider-track": {
    color: "#7AA89C",
  },
}));
const baseURL = process.env.REACT_APP_BACKEND_CHUNK;

const data = {
  gender: ["Male", "Female"],
  has_dependents: ["No", "Yes"],
  has_device_protection: ["No", "Yes"],
  has_multiple_lines: ["No phone service", "No", "Yes"],
  has_online_backup: ["No internet service", "No", "Yes"],
  has_online_security: ["No internet service", "No", "Yes"],
  has_paperless_billing: ["No", "Yes"],
  has_partner: ["No", "Yes"],
  has_phone_service: ["No", "Yes"],
  has_streaming_movies: ["No internet service", "No", "Yes"],
  has_streaming_ts: ["No internet service", "No", "Yes"],
  has_tech_support: ["No internet service", "No", "Yes"],
  monthly_charges: { min: 18, max: 120 },
  senior_citizen: ["0", "1"],
  tenure_months: { min: 1, max: 72 },
  total_charges: { min: 15, max: 900 },
  type_of_contract: ["Month-to-month", "One year", "Two year"],
  type_of_internet_service: ["Fiber optic", "DSL", "No"],
  type_of_payment_method: [
    "Electronic check",
    "Mailed check",
    "Bank transfer (automatic)",
    "Credit card (automatic)",
  ],
};

export default function ChurnRatePredictor() {
  const [onlineBackup, setOnlineBackup] = useState("Yes");
  const [streamingTS, setStreamingTS] = useState("Yes");
  const [montlyCharges, setmonthlyCharges] = useState(54.49);
  const [tenure, settenure] = useState(45);
  const [contractType, setContractType] = useState("Month-to-month");
  const [paymentMethod, setPaymentMethod] = useState("Electronic check");
  const [multipleLines, setMultipleLines] = useState("Yes");
  const [onlineSecurity, setOnlineSecurity] = useState("Yes");
  const [movieStreaming, setMovieStreaming] = useState("Yes");
  const [techSupport, setTechSupport] = useState("No");
  const [totalCharges, settotalCharges] = useState(290.94);
  const [gender, setGender] = useState("Male");
  const [hasDependents, setHasDependents] = useState("Yes");
  const [hasPaperlessBilling, setHasPaperlessBilling] = useState("Yes");
  const [internetServiceType, setInternetServiceType] = useState("Fiber optic");
  const [hasPhoneService, setHasPhoneService] = useState("Yes");
  const [hadDeviceProtection, setDeviceProtection] = useState("Yes");
  const [hasPartner, setPartner] = useState("Yes");
  const [seniorCitizen, setSeniorCitizen] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showModelDetails, setShowModelDetails] = useState(false);
  const [predict, setPredict] = useState(null);
  const { fetching } = useBackdrop();

  const randomValues = () => {
    const initialValue = initialValues();

    setGender(initialValue.gender);
    setHasDependents(initialValue.has_dependents);
    setOnlineBackup(initialValue.has_online_backup);
    setStreamingTS(initialValue.has_streaming_ts);
    setmonthlyCharges(initialValue.monthly_charges);
    settenure(initialValue.tenure_months);
    setContractType(initialValue.type_of_contract);
    setPaymentMethod(initialValue.type_of_payment_method);
    setMultipleLines(initialValue.has_multiple_lines);
    setOnlineSecurity(initialValue.has_online_security);
    setMovieStreaming(initialValue.has_streaming_movies);
    setTechSupport(initialValue.has_tech_support);
    settotalCharges(initialValue.total_charges);
    setHasPaperlessBilling(initialValue.has_paperless_billing);
    setHasPhoneService(initialValue.has_phone_service);
    setInternetServiceType(initialValue.type_of_internet_service);
    setDeviceProtection(initialValue.has_device_protection);
    setPartner(initialValue.has_partner);
    setSeniorCitizen(initialValue.senior_citizen);
  };

  function onSubmitForm() {
    fetching(true);
    axios
      .post(
        `${baseURL}/predict`,
        {
          gender,
          has_dependents: hasDependents,
          has_device_protection: hadDeviceProtection,
          has_multiple_lines: multipleLines,
          has_online_backup: onlineBackup,
          has_online_security: onlineSecurity,
          has_paperless_billing: hasPaperlessBilling,
          has_partner: hasPartner,
          has_phone_service: hasPhoneService,
          has_streaming_movies: movieStreaming,
          has_streaming_ts: streamingTS,
          has_tech_support: techSupport,
          monthly_charges: montlyCharges,
          senior_citizen: String(seniorCitizen).toString(),
          tenure_months: tenure,
          total_charges: totalCharges,
          type_of_contract: contractType,
          type_of_internet_service: internetServiceType,
          type_of_payment_method: paymentMethod,
          customer_ID: getRandomInt(10000),
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then(({ data }) => {
        debugger;
        setPredict(data.data?.churned__predicted);
        setDialogOpen(true);
      })
      .finally(() => {
        debugger;
        fetching(false);
      });
  }

  function getRandomIntM(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }

  const randomArray = (items) => {
    if (typeof items === "object" && !Array.isArray(items)) {
      return getRandomIntM(items.min, items.max);
    }
    const item = items[Math.floor(Math.random() * items.length)];
    return item;
  };

  const initialValues = () => {
    const json = {};

    Object.keys(data).forEach((key) => {
      json[key] = randomArray(data[key]);
    });
    return json;
  };

  function setTotalCharges(num) {
    num = num > 500 ? 500 : num;
    num = num < 0 ? 0 : num;
    settotalCharges(num);
  }
  function setMonthlyCharges(num) {
    num = num > 200 ? 200 : num;
    num = num < 0 ? 0 : num;
    setmonthlyCharges(num);
  }
  function setTenure(num) {
    num = num > 100 ? 100 : num;
    num = num < 0 ? 0 : num;
    settenure(num);
  }

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  return (
    <>
      <DemoViewHeader name={"Churn Rate Predictor"} />

      <form id="churn-rate-predictor">
        <div className="form-controls">
          <div className="left-section">
            <FormControl component="fieldset">
              <div className="radio-name">Gender:</div>
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <FormControlLabel
                  value="Female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="Male"
                  control={<Radio />}
                  label="Male"
                />
              </RadioGroup>
            </FormControl>
            <Divider />

            <FormControl component="fieldset">
              <div className="radio-name">Device Protection:</div>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={hadDeviceProtection}
                onChange={(e) => setDeviceProtection(e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Divider />

            <div className="form-control">
              <span>Online Backup</span>
              <Select
                onChange={(e) => setOnlineBackup(e.target.value)}
                value={onlineBackup}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="No internet service">
                  No Internet Service
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
            </div>
            <Divider />

            <FormControl component="fieldset">
              <div className="radio-name">Paperless Billing</div>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={hasPaperlessBilling}
                onChange={(e) => setHasPaperlessBilling(e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Divider />

            <FormControl component="fieldset">
              <div className="radio-name">Phone Service Active:</div>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={hasPhoneService}
                onChange={(e) => setHasPhoneService(e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Divider />

            <div className="form-control">
              <span>Streaming TS</span>
              <Select
                onChange={(e) => setStreamingTS(e.target.value)}
                value={streamingTS}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="No internet service">
                  No Internet Service
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
            </div>
            <Divider />

            <div className="has-slider form-control">
              <span>Monthly Charges:</span>
              <Slider
                sx={{
                  width: 260,
                }}
                value={montlyCharges}
                valueLabelDisplay="auto"
                onChange={(e) => setMonthlyCharges(e.target.value)}
                min={18}
                max={120}
              />
              <input
                type="number"
                min="18"
                max="120"
                className="slider-value"
                value={montlyCharges}
                onChange={(e) => setMonthlyCharges(e.target.value)}
              />
              {/* <span className="slider-value">{montlyCharges}</span> */}
            </div>
            <Divider />

            <div className="has-slider form-control">
              <span>Tenure in months:</span>
              <Slider
                sx={{
                  width: 260,
                }}
                value={tenure}
                valueLabelDisplay="auto"
                onChange={(e) => setTenure(e.target.value)}
                min={1}
                max={72}
              />
              <input
                type="number"
                min="1"
                max="72"
                className="slider-value"
                value={tenure}
                onChange={(e) => setTenure(e.target.value)}
              />
            </div>
            <Divider />

            <div className="form-control">
              <span>Type of Contract</span>
              <Select
                onChange={(e) => setContractType(e.target.value)}
                value={contractType}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="Month-to-month">Month-to-month</MenuItem>
                <MenuItem value="One year">One year</MenuItem>
                <MenuItem value="Two year">Two year</MenuItem>
              </Select>
            </div>
            <Divider />

            <div className="form-control">
              <span>Payment method:</span>
              <Select
                onChange={(e) => setPaymentMethod(e.target.value)}
                value={paymentMethod}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="Electronic check">Electronic check</MenuItem>
                <MenuItem value="Mailed check">Mailed check</MenuItem>
                <MenuItem value="Bank transfer (automatic)">
                  Bank transfer (automatic)
                </MenuItem>
                <MenuItem value="Credit card (automatic)">
                  Credit card (automatic)
                </MenuItem>
              </Select>
            </div>
          </div>

          <div className="right-section">
            <FormControl component="fieldset">
              <div className="radio-name">Dependents:</div>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={hasDependents}
                onChange={(e) => setHasDependents(e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Divider />

            <div className="form-control">
              <span>Multiple Lines:</span>
              <Select
                onChange={(e) => setMultipleLines(e.target.value)}
                value={multipleLines}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="No phone service">No Phone Service</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
            </div>
            <Divider />

            <div className="form-control">
              <span>Online Security:</span>
              <Select
                onChange={(e) => setOnlineSecurity(e.target.value)}
                value={onlineSecurity}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="No internet service">
                  No Internet Service
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
            </div>
            <Divider />

            <FormControl component="fieldset">
              <div className="radio-name">Partner:</div>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={hasPartner}
                onChange={(e) => setPartner(e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Divider />

            <div className="form-control">
              <span>Tech support active</span>
              <Select
                onChange={(e) => setTechSupport(e.target.value)}
                value={techSupport}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="No internet service">
                  No Internet Service
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
            </div>
            <Divider />

            <div className="form-control">
              <span>Movie Streaming Service</span>
              <Select
                onChange={(e) => setMovieStreaming(e.target.value)}
                value={movieStreaming}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="No internet service">
                  No Internet Service
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
            </div>
            <Divider />

            <FormControl component="fieldset">
              <div className="radio-name">Senior Citizen:</div>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={seniorCitizen}
                onChange={(e) => setSeniorCitizen(e.target.value)}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="1" control={<Radio />} label="1" />
              </RadioGroup>
            </FormControl>
            <Divider />

            <div className="has-slider form-control">
              <span>Total Charges:</span>
              <Slider
                sx={{
                  width: 260,
                }}
                value={totalCharges}
                valueLabelDisplay="auto"
                onChange={(e) => setTotalCharges(e.target.value)}
                min={15}
                max={900}
              />
              <input
                type="number"
                min="15"
                max="900"
                className="slider-value"
                value={totalCharges}
                onChange={(e) => setTotalCharges(e.target.value)}
              />
            </div>
            <Divider />

            <div className="form-control">
              <span>Type of Internet service:</span>
              <Select
                onChange={(e) => setInternetServiceType(e.target.value)}
                value={internetServiceType}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="DSL">DSL</MenuItem>
                <MenuItem value="Fiber optic">Fiber optic</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <div className="action-buttons">
          <div
            className="random-values secondary"
            onClick={() => setShowModelDetails(true)}
          >
            Model Details
          </div>
          <div className="random-values" onClick={randomValues}>
            Random Values
          </div>
          <div onClick={onSubmitForm} className="predict">
            Predict
          </div>
        </div>
      </form>
      <ModelDetails
        open={showModelDetails}
        onClose={() => setShowModelDetails(false)}
        image={ModelInfo}
        moreInfo={Formula}
      />
      <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <div className="churn-risk-result">
          <h2>Churn Risk: </h2>
          {/* <h1>Not Detected</h1> */}
          <Typography
            fontSize={50}
            style={{ marginBottom: "1rem" }}
            sx={{
              marginBottom: "10px !important",
              color: predict !== "Yes" ? "primary.main" : "error.main",
              fontWeight: "bold",
            }}
          >
            {predict === "No" ? "Not " : ""} Detected!
          </Typography>
          <div
            onClick={() => setDialogOpen(false)}
            className="standard-primary-button"
          >
            Ok
          </div>
        </div>
      </Dialog>
    </>
  );
}
