import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import TablePagination from "@mui/material/TablePagination";
import { useState } from "react";
// import Pagination from "@mui/material/Pagination";

import DemoViewHeader from "./DemoViewHeader";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
// import BikesData from "util/data";
import BikesData from "util/bikes-sharing-predictions-cleaned.json";
import moment from "moment";
import { Dialog } from "@mui/material";
import { useBackdrop } from "components/context/BackdropProvider";

const dateFormat = "YYYY-MM-DD";

function ModelDetails({ open, onClose }) {
  return (
    <Dialog
      sx={{ maxWidth: "none" }}
      open={open}
      onClose={onClose}
      maxWidth={false}
    >
      <div className="bikes-model-details">
        <div className="row">
          <h1>Single Step Forecast</h1>
          <div className="labels">
            <div className="label">
              <div className="line"></div>
              <div>Labels</div>
            </div>
            <div className="label">
              <div className="line line-with-circle">
                <div className="circle-center"></div>
              </div>
              <div>Predictions</div>
            </div>
          </div>
        </div>

        <img src="/demo/bikes-model-details.jpg" alt="" />
        <button onClick={onClose} className="standard-primary-button">
          Ok
        </button>
      </div>
    </Dialog>
  );
}

function createData(
  date,
  holiday,
  weekday,
  workingDay,
  weatherSituation,
  humidityPercent,
  avgTempC,
  windSpeedKM,
  casualUsers,
  registeredUsers,
  totalUsers
) {
  return {
    date,
    holiday,
    weekday,
    workingDay,
    weatherSituation,
    humidityPercent,
    avgTempC,
    windSpeedKM,
    casualUsers,
    registeredUsers,
    totalUsers,
  };
}

function DatePicker({ onChange }) {
  // let [date, setDate] = useState("12/02/2012");
  let [date, setDate] = useState("2012/12/02");
  // function onMonthChange() {
  //   let dateParts = date.split("/");
  //   dateParts[1] = "12";
  //   setDate(dateParts.join("/"));
  // }
  return (
    <div className="bikes-date">
      <span className="date-label">Select a day to predict demand on:</span>
      <LocalizationProvider
        className="bikes-date-picker"
        dateAdapter={AdapterDateFns}
      >
        <DesktopDatePicker
          className="bikes-date-picker"
          renderInput={(params) => (
            <TextField
              className="bikes-date-picker"
              sx={{
                border: "1px solid #7AA89C !important",
                borderRadius: "4px",
                "& *": {
                  border: "0px solid black",
                  outline: "none",
                },
                outline: "none",
                width: 180,
                "MuiOutlinedInput-input": {
                  width: "180px !important",
                },
              }}
              {...params}
            />
          )}
          onChange={(val) => {
            console.log(val);
            setDate(val);
            onChange(val);
          }}
          mask="____/__/__"
          inputFormat="yyyy/MM/dd"
          onMonthChange={(data) => {
            console.log("Month changed", data);
          }}
          value={date}
          views={["day"]}
          components={{
            LeftArrowButton: () => <div></div>,
            RightArrowButton: () => <div></div>,
          }}
        />
      </LocalizationProvider>
    </div>
  );
}

const rows = [];

for (let i = 0; i <= 33; i++) {
  rows.push(createData("2012-11-23", 0, 5, 5, i));
}

export default function BikesDemandForecast() {
  // const [tablePage, setTablePage] = useState(0);
  const [displayModelDetails, setDisplayModelDetails] = useState(false);
  const [predictDate, setPredictDate] = useState("2012/12/02");
  const [predictValue, setPredictValue] = useState(null);
  const [alert, setAlert] = useState(false);

  const { fetching } = useBackdrop();
  // let tableRows = BikesData.slice(tablePage * 10, tablePage * 10 + 10);
  let tableRows = BikesData;

  const handlePredict = () => {
    const nextDay = moment(predictDate).add(1, "days").format(dateFormat);

    const value = BikesData.find((item) => item.dteday === nextDay);
    // console.log(nextDay, value);
    fetching(true);
    setTimeout(() => {
      setPredictValue(value?.predicted_total_users);
      setAlert(true);
      fetching(false)
    }, 2000)
  };

  const handleDateChange = (date) => {
    setPredictDate(date);
  };

  return (
    <>
      <DemoViewHeader
        name="Bikes Demand Forecast"
        thirdComponent={DatePicker}
        onChange={handleDateChange}
      />
      <div id="bikes-demand-forecast">
        <TableContainer className="table" component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow
                className="table-head-row"
                sx={{
                  height: 81,
                  backgroundColor: "#BFD7D0",
                }}
              >
                <TableCell>Date</TableCell>
                <TableCell align="right">Holiday</TableCell>
                <TableCell align="right">Weekday</TableCell>
                <TableCell align="right">Working Day</TableCell>
                <TableCell align="right">Weather Situation</TableCell>
                <TableCell align="right">Humidity Percent</TableCell>
                <TableCell align="right">Avg Temperature Celsius</TableCell>
                <TableCell align="right">Wind speed k/m</TableCell>
                <TableCell align="right">Casual Users</TableCell>
                <TableCell align="right">Registered Users</TableCell>
                <TableCell align="right">Total Users</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows.map((row, index) => {
                let backgroundColor = index % 2 === 0 ? "#F2F2F2" : "#F9F9F9";
                return (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor,
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.dteday}
                    </TableCell>
                    <TableCell align="right">{row.holiday}</TableCell>
                    <TableCell align="right">{row.weekday}</TableCell>
                    <TableCell align="right">{row.workingday}</TableCell>
                    <TableCell align="right">{row.weather_situation}</TableCell>
                    <TableCell align="right">{row.humidity_percent}</TableCell>
                    <TableCell align="right">
                      {row.avg_temperature_celsius}
                    </TableCell>
                    <TableCell align="right">{row.windspeed_km_h}</TableCell>
                    <TableCell align="right">{row.casual_users}</TableCell>
                    <TableCell align="right">{row.registered_users}</TableCell>
                    <TableCell align="right">{row.total_users}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="actions">
          <span div="no-display"></span>
          <span div="no-display"></span>
          {/* <Pagination
            className="table-pagination"
            // color="#BFD7D0"
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "30px",
              li: {
                margin: "3px",
                // padding: 0,
                backgroundColor: "#BFD7D0",
                borderRadius: "4px",
              },
              "li > *": {
                color: "white",
                fontWeight: "bold",
                width: "100%",
                padding: "0",
                margin: 0,
                borderRadius: "4px",
                textAlign: "center",
              },
              "li:hover >*": {
                backgroundColor: "#304f4e !important",
              },
              "li .MuiButtonBase-root ": {
                width: "100%",
              },

              "li > *.Mui-selected": {
                backgroundColor: "#416C6A",
                color: "white",
              },
              "li:hover > *.Mui-selected": {
                backgroundColor: "#304f4e",
                color: "white",
              },
            }}
            onChange={(e, page) => setTablePage(page - 1)}
            count={3}
          /> */}

          <div className="btn-container">
            <div
              onClick={() => setDisplayModelDetails(true)}
              className="model-button"
            >
              Model Details
            </div>
            <div className="button" onClick={handlePredict}>
              Predict
            </div>
          </div>
        </div>
      </div>
      <ModelDetails
        open={displayModelDetails}
        onClose={() => {
          setDisplayModelDetails(false);
        }}
      />
      <Dialog onClose={() => setAlert(false)} open={alert}>
        <div className="bikes-predict-result">
          <h2>Predicted demand for:</h2>
          <h3>{moment(predictDate).format(dateFormat)}</h3>
          <div>
            <h1 className="result">{predictValue}</h1>
            <h3 className="result">rented bicycles</h3>
          </div>
          <div className="use-case-button" onClick={() => setAlert(false)}>
            Ok
          </div>
        </div>
      </Dialog>
    </>
  );
}
