import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { Stack, Typography } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DAEDF0",
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 20,
    borderBottom: "1px solid white",
    padding: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: "1px solid white",
    padding: 10,
    [`&.marked`]: {
        backgroundColor: '#56A8B9'
    }
  },
}));

const SecondaryTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ED6C3A",
    color: "white",
    fontWeight: 700,
    fontSize: 20,
    borderBottom: "1px solid white",
    padding: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#f3c3b092",
    borderBottom: "1px solid white",
    padding: 10,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables({
  columns,
  data,
  pagination,
  page,
  setPage,
  mark,
  progress
}) {
  const rowPerPage = 10;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const stableData = React.useMemo(() => {
    if (!pagination) return data;
    return data.slice(
      (page - 1) * rowPerPage,
      (page - 1) * rowPerPage + rowPerPage
    );
  }, [data, pagination, page]);

  const setClassName = (row, column) => {
      if(mark && column.key === 'label') {
          return row.label !== row.trueLabel ? 'marked': ''
      }
      return ''
  }

  const getValues = (row, column) => {
    if(mark && column.key === 'label') {
      return row.trueLabel;
    }

    return row[column.key]
  }
  
  return (
    <>
      <TableContainer component={Paper} sx={{ borderRadius: 0, height: 454 }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => {
                if (index !== columns.length - 1) {
                  return <StyledTableCell> {column.name}</StyledTableCell>;
                }
                return <SecondaryTableCell>{column.name}</SecondaryTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableData.map((row) => (
              <StyledTableRow key={row.name}>
                {columns.map((column, index) => {
                  if (index !== columns.length - 1) {
                    return (
                      <StyledTableCell className={setClassName(row, column)}> {getValues(row, column)}</StyledTableCell>
                    );
                  }
                  return (
                    <SecondaryTableCell>{row[column.key]}</SecondaryTableCell>
                  );
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <Stack direction={"row"} alignItems="center">
          <Pagination
            sx={{ mt: 2 }}
            count={Math.round(data.length / rowPerPage) + 1}
            shape="rounded"
            rowsPerPage={rowPerPage}
            page={page}
            onChange={handleChangePage}
            color={"primary"}
          />
          <Typography mt={2} fontWeight={700} fontSize={18} color={"primary"}>
            PROCESSED RECORDS:{" "}
            <Typography fontSize={18} fontWeight={700} variant="caption" sx={{color: '#637E81'}}>
              {" "}
              {Math.round(progress * 100)}
              %
            </Typography>
          </Typography>
        </Stack>
      )}
    </>
  );
}
