import React from "react";
import { useModulosBody } from "../context/ModulosBodyProvider";

export default function ListItem({ img, name, link }) {
  let [modulosBody, setModulosBody] = useModulosBody();
  let isActive = modulosBody.activeMainView === name;

  function handleClick() {
    if (link) {
      window.open(link);
      return;
    }

    setModulosBody((existingBody) => {
      return {
        ...existingBody,
        activeMainView: name,
      };
    });
  }

  return (
    <div onClick={handleClick} className={isActive ? "active-list-view" : null}>
      <img className="item-img" src={`${img}`} alt="" />
      <div className="item-name">{name}</div>
    </div>
  );
}
