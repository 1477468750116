import React from "react";

export default function Galaxy() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2623 30.5512C12.6129 30.4517 12.0074 30.896 11.908 31.5442C11.2018 36.1268 11.5497 40.7099 12.9413 45.1667C14.3426 49.657 16.9806 53.6206 20.5695 56.6285C23.8177 59.3512 27.6996 61.1792 31.8463 61.9519C32.1997 63.5474 33.6249 64.7449 35.3253 64.7449C36.9196 64.7449 38.2721 63.6921 38.7262 62.2455C40.3748 62.082 42.016 61.7499 43.6169 61.2507C54.0552 57.9944 59.8981 46.8519 56.6412 36.4118C53.9954 27.9377 44.9481 23.1946 36.4734 25.8381C33.128 26.8818 30.3894 29.1657 28.7622 32.2693C27.135 35.373 26.8134 38.9246 27.8571 42.2699C28.7115 45.0068 30.5798 47.2474 33.1195 48.5791C35.6586 49.9103 38.5638 50.1728 41.3007 49.3183C41.9269 49.123 42.2754 48.4571 42.0801 47.8302C41.8848 47.204 41.2183 46.8555 40.592 47.0508C38.4619 47.7161 36.1993 47.511 34.2224 46.4747C32.2455 45.4383 30.7898 43.6933 30.1245 41.5619C29.27 38.8227 29.5331 35.9137 30.8661 33.3722C32.1991 30.8307 34.4415 28.96 37.1808 28.1055C44.4055 25.8521 52.1179 29.8957 54.3732 37.1192C57.24 46.308 52.0972 56.1164 42.9095 58.9826C41.5094 59.419 40.0763 59.7138 38.6365 59.8664C38.1116 58.5505 36.8262 57.6172 35.3253 57.6172C33.9325 57.6172 32.7252 58.4211 32.1393 59.5881C28.4473 58.8752 24.9927 57.2358 22.0959 54.8078C18.8562 52.0923 16.4746 48.5138 15.2093 44.4587C13.9264 40.3504 13.606 36.1274 14.256 31.9056C14.3555 31.258 13.9111 30.6513 13.2623 30.5512ZM35.3253 59.9933C35.9802 59.9933 36.5131 60.5262 36.5131 61.1811C36.5131 61.836 35.9802 62.3688 35.3253 62.3688C34.6698 62.3688 34.1376 61.836 34.1376 61.1811C34.1376 60.5262 34.6698 59.9933 35.3253 59.9933Z"
        fill="#637E81"
      />
      <path
        d="M79.9455 31.886C76.9536 22.2986 70.407 14.4501 61.5129 9.78644C52.6177 5.12276 42.4389 4.20235 32.8515 7.1949C25.8813 9.36957 19.9884 13.9374 16.1505 20.1093C15.7806 20.0098 15.3924 19.9561 14.9921 19.9561C12.5299 19.9561 10.5267 21.9593 10.5267 24.4214C10.5267 26.8836 12.5299 28.8861 14.9921 28.8861C17.4542 28.8861 19.4574 26.8836 19.4574 24.4214C19.4574 23.216 18.9764 22.121 18.197 21.3166C21.7346 15.6525 27.1533 11.4612 33.5589 9.46235C42.5408 6.65901 52.0769 7.52143 60.4094 11.8903C68.7425 16.2598 74.8753 23.6121 77.6781 32.5934C77.8368 33.1018 78.3055 33.4277 78.8121 33.4277C78.9293 33.4277 79.0483 33.4107 79.1661 33.3734C79.7917 33.1781 80.1414 32.5122 79.9455 31.886ZM14.9921 26.5106C13.8397 26.5106 12.9022 25.5732 12.9022 24.4214C12.9022 23.2691 13.8397 22.3316 14.9921 22.3316C16.1438 22.3316 17.0813 23.2691 17.0813 24.4214C17.0813 25.5732 16.1438 26.5106 14.9921 26.5106Z"
        fill="#637E81"
      />
      <path
        d="M32.2173 18.9081C22.3034 23.5236 17.9931 35.3436 22.6086 45.2576C25.3362 51.1139 31.1608 54.5618 37.232 54.5612C39.5043 54.5612 41.8115 54.0784 44.0038 53.0579C50.5609 50.0049 53.4124 42.1851 50.36 35.6262C47.8613 30.2613 41.4642 27.9285 36.0992 30.4267C35.5047 30.7031 35.2465 31.4099 35.5236 32.0044C35.8007 32.5995 36.5069 32.8571 37.102 32.58C41.2792 30.636 46.2609 32.4518 48.2061 36.629C50.7061 42.0001 48.3709 48.404 43.001 50.9039C36.1395 54.0985 27.9571 51.1151 24.7619 44.2548C20.6994 35.5286 24.494 25.1239 33.2202 21.0614C38.4942 18.606 44.4201 18.3191 49.9066 20.2533C55.0121 22.0539 59.2077 25.5896 61.8334 30.2741C60.6872 31.0834 59.9365 32.417 59.9365 33.9234C59.9365 36.3855 61.9396 38.3887 64.4018 38.3887C66.8639 38.3887 68.8671 36.3855 68.8671 33.9234C68.8671 31.4612 66.8639 29.458 64.4018 29.458C64.3005 29.458 64.2004 29.4629 64.1009 29.4696C61.2084 24.1034 56.48 20.0519 50.6963 18.0127C44.6209 15.8704 38.0584 16.1884 32.2173 18.9081ZM66.4916 33.9234C66.4916 35.0751 65.5541 36.0126 64.4018 36.0126C63.2494 36.0126 62.3126 35.0751 62.3126 33.9234C62.3126 32.771 63.2494 31.8335 64.4018 31.8335C65.5541 31.8335 66.4916 32.771 66.4916 33.9234Z"
        fill="#637E81"
      />
      <path
        d="M67.5256 41.4752C67.4804 40.8203 66.9134 40.3259 66.2591 40.3717C65.6048 40.4169 65.1104 40.9845 65.1562 41.6388C65.5413 47.2156 64.2584 52.7307 61.4471 57.5897C58.5723 62.558 54.2913 66.4697 49.0667 68.902C40.5371 72.8735 30.971 73.2855 22.1313 70.0616C13.2922 66.8384 6.23654 60.3656 2.26498 51.8359C1.98788 51.2415 1.2817 50.9833 0.686609 51.2604C0.0921269 51.5375 -0.165442 52.2443 0.111048 52.8387C4.35116 61.9434 11.8823 68.8525 21.3171 72.2937C25.5206 73.8263 29.8767 74.5892 34.2212 74.5892C39.6301 74.5892 45.0201 73.4064 50.0695 71.0553C55.7373 68.4167 60.3827 64.1717 63.5034 58.7793C66.5527 53.5095 67.9437 47.5256 67.5256 41.4752Z"
        fill="#637E81"
      />
      <path
        d="M39.3902 33.3655C36.0174 33.3655 33.2739 36.1096 33.2739 39.4818C33.2739 42.854 36.0174 45.5975 39.3902 45.5975C42.7624 45.5975 45.5059 42.854 45.5059 39.4818C45.5059 36.1096 42.7624 33.3655 39.3902 33.3655ZM39.3902 43.2227C37.3278 43.2227 35.6494 41.5442 35.6494 39.4818C35.6494 37.4194 37.3278 35.7416 39.3902 35.7416C41.4526 35.7416 43.1304 37.4194 43.1304 39.4818C43.1304 41.5442 41.4526 43.2227 39.3902 43.2227Z"
        fill="#1F2E3C"
      />
      <path
        d="M68.2969 68.41H67.5975V67.7099C67.5975 67.0538 67.0652 66.5222 66.4097 66.5222C65.7536 66.5222 65.222 67.0538 65.222 67.7099V68.41H64.5219C63.8658 68.41 63.3342 68.9416 63.3342 69.5977C63.3342 70.2538 63.8658 70.7855 64.5219 70.7855H65.222V71.4849C65.222 72.1411 65.7536 72.6727 66.4097 72.6727C67.0652 72.6727 67.5975 72.1411 67.5975 71.4849V70.7855H68.2969C68.9531 70.7855 69.4847 70.2538 69.4847 69.5977C69.4847 68.9416 68.9531 68.41 68.2969 68.41Z"
        fill="#1F2E3C"
      />
      <path
        d="M61.1248 14.7272C60.4686 14.7272 59.9364 15.2589 59.9364 15.915V16.6144H59.2369C58.5808 16.6144 58.0492 17.1461 58.0492 17.8022C58.0492 18.4583 58.5808 18.9899 59.2369 18.9899H59.9364V19.69C59.9364 20.3461 60.4686 20.8778 61.1248 20.8778C61.7803 20.8778 62.3125 20.3461 62.3125 19.69V18.9899H63.012C63.6681 18.9899 64.1997 18.4583 64.1997 17.8022C64.1997 17.1461 63.6681 16.6144 63.012 16.6144H62.3125V15.915C62.3125 15.2589 61.7803 14.7272 61.1248 14.7272Z"
        fill="#1F2E3C"
      />
      <path
        d="M10.8582 11.4387H11.5576V12.1381C11.5576 12.7943 12.0892 13.3259 12.7454 13.3259C13.4015 13.3259 13.9331 12.7943 13.9331 12.1381V11.4387H14.6332C15.2887 11.4387 15.8209 10.907 15.8209 10.2509C15.8209 9.59479 15.2887 9.06256 14.6332 9.06256H13.9331V8.3631C13.9331 7.70697 13.4015 7.17535 12.7454 7.17535C12.0892 7.17535 11.5576 7.70697 11.5576 8.3631V9.06256H10.8582C10.202 9.06256 9.6698 9.59479 9.6698 10.2509C9.6698 10.907 10.202 11.4387 10.8582 11.4387Z"
        fill="#1F2E3C"
      />
      <path
        d="M9.82666 59.0485H10.5267V59.7485C10.5267 60.4047 11.0583 60.9363 11.7145 60.9363C12.3706 60.9363 12.9022 60.4047 12.9022 59.7485V59.0485H13.6017C14.2578 59.0485 14.7894 58.5169 14.7894 57.8607C14.7894 57.2046 14.2578 56.673 13.6017 56.673H12.9022V55.9735C12.9022 55.3174 12.3706 54.7858 11.7145 54.7858C11.0583 54.7858 10.5267 55.3174 10.5267 55.9735V56.673H9.82666C9.17114 56.673 8.63892 57.2046 8.63892 57.8607C8.63892 58.5169 9.17053 59.0485 9.82666 59.0485Z"
        fill="#1F2E3C"
      />
      <path
        d="M46.2529 46.3892C46.2529 47.1759 45.6145 47.8143 44.8271 47.8143C44.0404 47.8143 43.402 47.1759 43.402 46.3892C43.402 45.6018 44.0404 44.9634 44.8271 44.9634C45.6145 44.9634 46.2529 45.6018 46.2529 46.3892Z"
        fill="#637E81"
      />
      <path
        d="M40.8154 22.1198C40.8154 22.9072 40.1776 23.545 39.3902 23.545C38.6028 23.545 37.9644 22.9072 37.9644 22.1198C37.9644 21.3325 38.6028 20.694 39.3902 20.694C40.1776 20.694 40.8154 21.3325 40.8154 22.1198Z"
        fill="#1F2E3C"
      />
      <path
        d="M51.0571 63.5571C51.0571 64.3438 50.4186 64.9822 49.6319 64.9822C48.8445 64.9822 48.2061 64.3438 48.2061 63.5571C48.2061 62.7697 48.8445 62.1313 49.6319 62.1313C50.4186 62.1313 51.0571 62.7697 51.0571 63.5571Z"
        fill="#637E81"
      />
      <path
        d="M8.92944 37.2009C8.92944 37.9877 8.29163 38.6261 7.50427 38.6261C6.71692 38.6261 6.0791 37.9877 6.0791 37.2009C6.0791 36.4136 6.71692 35.7751 7.50427 35.7751C8.29163 35.7751 8.92944 36.4136 8.92944 37.2009Z"
        fill="#637E81"
      />
      <path
        d="M74.4963 38.6261C74.4963 39.4134 73.8578 40.0513 73.0711 40.0513C72.2838 40.0513 71.6453 39.4134 71.6453 38.6261C71.6453 37.8387 72.2838 37.2009 73.0711 37.2009C73.8578 37.2009 74.4963 37.8387 74.4963 38.6261Z"
        fill="#637E81"
      />
    </svg>
  );
}
