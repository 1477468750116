import MuiRadio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import MuiSlider from "@mui/material/Slider";
import Dialog from "@mui/material/Dialog";
import ModelDetails from "./ModelDetails";
import { styled } from "@mui/material/styles";
import DemoViewHeader from "./DemoViewHeader";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";

// import ModelInfo from "../../../assets/images/feature_importance (2).png";
import Curve from "../../../assets/images/ROCCurve_graph_0.svg";
import { useBackdrop } from "components/context/BackdropProvider";

const Radio = styled(MuiRadio)(() => ({
  color: "#7AA89C",
  paddingLeft: 0,
  "&.Mui-checked": {
    color: "#7AA89C",
  },
}));

const Select = styled(MuiSelect)(() => ({
  borderRadius: 4,
  height: 40,
  "& >*": {
    border: "none",
  },
}));

const Slider = styled(MuiSlider)(() => ({
  color: "white",
  "& .MuiSlider-thumb": {
    border: "2px solid #7AA89C",
  },
  "& .MuiSlider-track": {
    color: "#7AA89C", 
  },
}));

const data = {
  age: { min: 10, max: 100 },
  jobType: [
    "Admin",
    "Blue-Collar",
    "Entrepreneur",
    "House Maid",
    "Management",
    "Retired",
    "Self-Employed",
    "Services",
    "Student",
    "Technician",
    "Unemployed",
    "Unknown",
  ],
  maritalStatus: ["Single", "Married", "Divorced", "Unknown"],
  educationLevel: ["Primary", "Secondary", "Tertiary", "Unknown"],
  creditInDefault: ["Yes", "No"],
  balance: { min: -6847, max: 102127},
  housingLoan: ["Yes", "No"],
  personalLoan: ["Yes", "No"],
  communicationType: ["Telephone", "Cellular", "Unknown"],
  lastContactDayOfTheWeek: { min: 1, max: 54 },
  lastContactMonthOfTheYear: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Oct",
    "Nov",
    "Dev",
  ],
  contactDuration: { min: 0, max: 300 },
  numberOfTouchPoints: { min: 1, max: 100 },
  intervalBetweenTouchPoints: { min: -1, max: 300 },
  otherTouchPoints: { min: 1, max: 300 },
  marketingCampaignOutcome: ["Failure", "Other", "Success", "Unknown"],
  // label: [0, 1],
};

export default function LongTermDeposit() {
  const [age, setAge] = useState(21);
  const [jobType, setJobType] = useState("Unknown");
  const [maritalStatus, setMaritalStatus] = useState("Single");
  const [educationLevel, setEducationLevel] = useState("Secondary");
  const [creditInDefault, setCreditInDefault] = useState("No");
  const [balance, setBalance] = useState(100);
  const [housingLoan, setHousingLoan] = useState("No");
  const [personalLoan, setPersonalLoan] = useState("No");
  const [communicationType, setCommunicationType] = useState("Telephone");
  const [lastContactDayOfTheWeek, setLastContactDayOfTheWeek] = useState(12);
  const [lastContactMonthOfTheYear, setLastContactMonthOfTheYear] =
    useState("Jun");
  const [contactDuration, setContactDuration] = useState(100);
  const [numberOfTouchPoints, setNumberOfTouchPoints] = useState(50);
  const [intervalBetweenTouchPoints, setIntervalBetweenTouchPoints] =
    useState(150);
  const [otherTouchPoints, setOtherTouchPoints] = useState(150);
  const [marketingCampaignOutcome, setMarketingCampaignOutcome] =
    useState("Unknown");
  // const [label, setLabel] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [showModelDetails, setShowModelDetails] = useState(false);
  const [predict, setPredict] = useState(null);
  const { fetching } = useBackdrop();

  const randomValues = () => {
    const initialValue = initialValues();

    setAge(initialValue.age);
    setJobType(initialValue.jobType);
    setMaritalStatus(initialValue.maritalStatus);
    setEducationLevel(initialValue.educationLevel);
    setCreditInDefault(initialValue.creditInDefault);
    setBalance(initialValue.balance);
    setHousingLoan(initialValue.housingLoan);
    setPersonalLoan(initialValue.personalLoan);
    setCommunicationType(initialValue.communicationType);
    setLastContactDayOfTheWeek(initialValue.lastContactDayOfTheWeek);
    setLastContactMonthOfTheYear(initialValue.lastContactMonthOfTheYear);
    setContactDuration(initialValue.contactDuration);
    setNumberOfTouchPoints(initialValue.numberOfTouchPoints);
    setIntervalBetweenTouchPoints(initialValue.intervalBetweenTouchPoints);
    setOtherTouchPoints(initialValue.otherTouchPoints);
    setMarketingCampaignOutcome(initialValue.marketingCampaignOutcome);
    // setLabel(initialValue.label);
  };

  function onSubmitForm() {
    fetching(true);
    setTimeout(() => {
      const randomValue = getRandomIntM(60, 90);
      setPredict(randomValue);
      setDialogOpen(true);
      fetching(false);
    }, 2000);
  }

  function getRandomIntM(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }

  const randomArray = (items) => {
    if (typeof items === "object" && !Array.isArray(items)) {
      return getRandomIntM(items.min, items.max);
    }
    const item = items[Math.floor(Math.random() * items.length)];
    return item;
  };

  const initialValues = () => {
    const json = {};

    Object.keys(data).forEach((key) => {
      json[key] = randomArray(data[key]);
    });
    return json;
  };

  return (
    <>
      <DemoViewHeader name={"Long Term Deposit Campaign"} />

      <form id="long-term-deposit-campaign">
        <div className="form-controls">
          <div className="left-section">
            <div className="has-slider form-control">
              <span>Age:</span>
              <Slider
                sx={{
                  width: 260,
                }}
                value={age}
                valueLabelDisplay="auto"
                onChange={(e) => setAge(e.target.value)}
                min={15}
                max={120}
              />
              <input
                type="number"
                min="15"
                max="120"
                className="slider-value"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </div>
            <Divider />

            <div className="form-control">
              <span>Job Type:</span>
              <Select
                onChange={(e) => setJobType(e.target.value)}
                value={jobType}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Blue-Collar">Blue-Collar</MenuItem>
                <MenuItem value="Entrepreneur">Entrepreneur</MenuItem>
                <MenuItem value="House Maid">House Maid</MenuItem>
                <MenuItem value="Management">Management</MenuItem>
                <MenuItem value="Retired">Retired</MenuItem>
                <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                <MenuItem value="Services">Services</MenuItem>
                <MenuItem value="Student">Student</MenuItem>
                <MenuItem value="Technician">Technician</MenuItem>
                <MenuItem value="Unemployed">Unemployed</MenuItem>
                <MenuItem value="Unknown">Unknown</MenuItem>
              </Select>
            </div>
            <Divider />

            <div className="form-control">
              <span>Marital Status:</span>
              <Select
                onChange={(e) => setMaritalStatus(e.target.value)}
                value={maritalStatus}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="Single">Single</MenuItem>
                <MenuItem value="Married">Married</MenuItem>
                <MenuItem value="Divorced">Divorced</MenuItem>
                <MenuItem value="Unknown">Unknown</MenuItem>
              </Select>
            </div>
            <Divider />

            <div className="form-control">
              <span>Education Level:</span>
              <Select
                onChange={(e) => setEducationLevel(e.target.value)}
                value={educationLevel}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="Primary">Primary</MenuItem>
                <MenuItem value="Secondary">Secondary</MenuItem>
                <MenuItem value="Tertiary">Tertiary</MenuItem>
                <MenuItem value="Unknown">Unknown</MenuItem>
              </Select>
            </div>
            <Divider />

            <FormControl component="fieldset">
              <div className="radio-name">Credit In Default:</div>
              <RadioGroup
                row
                aria-label="creditInDefault"
                name="row-radio-buttons-group"
                value={creditInDefault}
                onChange={(e) => setCreditInDefault(e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Divider />

            <div className="has-slider form-control">
              <span>Balance:</span>
              <Slider
                sx={{
                  width: 260,
                }}
                value={balance}
                valueLabelDisplay="auto"
                onChange={(e) => setBalance(e.target.value)}
                min={data.balance.min}
                max={data.balance.max}
              />
              <input
                type="number"
                min={data.balance.min}
                max={data.balance.max}
                className="slider-value"
                value={balance}
                onChange={(e) => setBalance(e.target.value)}
              />
            </div>
            <Divider />

            <FormControl component="fieldset">
              <div className="radio-name">Housing Loan:</div>
              <RadioGroup
                row
                aria-label="housingLoan"
                name="row-radio-buttons-group"
                value={housingLoan}
                onChange={(e) => setHousingLoan(e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Divider />

            <FormControl component="fieldset">
              <div className="radio-name">Personal Loan:</div>
              <RadioGroup
                row
                aria-label="personalLoan"
                name="row-radio-buttons-group"
                value={personalLoan}
                onChange={(e) => setPersonalLoan(e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Divider />

            <div className="form-control">
              <span>Communication Type:</span>
              <Select
                onChange={(e) => setCommunicationType(e.target.value)}
                value={communicationType}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="Telephone">Telephone</MenuItem>
                <MenuItem value="Cellular">Cellular</MenuItem>
                <MenuItem value="Unknown">Unknown</MenuItem>
              </Select>
            </div>
            <Divider />
          </div>

          <div className="right-section">
            <div className="has-slider form-control">
              <span>Last Contact Day Of The Week:</span>
              <Slider
                sx={{
                  width: 260,
                }}
                value={lastContactDayOfTheWeek}
                valueLabelDisplay="auto"
                onChange={(e) => setLastContactDayOfTheWeek(e.target.value)}
                min={1}
                max={54}
              />
              <input
                type="number"
                min="1"
                max="54"
                className="slider-value"
                value={lastContactDayOfTheWeek}
                onChange={(e) => setLastContactDayOfTheWeek(e.target.value)}
              />
            </div>
            <Divider />

            <div className="form-control">
              <span>Last Contact Month Of The Year:</span>
              <Select
                onChange={(e) => setLastContactMonthOfTheYear(e.target.value)}
                value={lastContactMonthOfTheYear}
                sx={{
                  backgroundColor: "white",
                }}
              >
                <MenuItem value="Jan">Jan</MenuItem>
                <MenuItem value="Feb">Feb</MenuItem>
                <MenuItem value="Mar">Mar</MenuItem>
                <MenuItem value="Apr">Apr</MenuItem>
                <MenuItem value="May">May</MenuItem>
                <MenuItem value="Jun">Jun</MenuItem>
                <MenuItem value="Jul">Jul</MenuItem>
                <MenuItem value="Agu">Agu</MenuItem>
                <MenuItem value="Sep">Sep</MenuItem>
                <MenuItem value="Oct">Oct</MenuItem>
                <MenuItem value="Nov">Nov</MenuItem>
                <MenuItem value="Dec">Dec</MenuItem>
              </Select>
            </div>
            <Divider />

            <div className="has-slider form-control">
              <span>Contact Duration [sec]:</span>
              <Slider
                sx={{
                  width: 260,
                }}
                value={contactDuration}
                valueLabelDisplay="auto"
                onChange={(e) => setContactDuration(e.target.value)}
                min={1}
                max={300}
              />
              <input
                type="number"
                min="1"
                max="300"
                className="slider-value"
                value={contactDuration}
                onChange={(e) => setContactDuration(e.target.value)}
              />
            </div>
            <Divider />

            <div className="has-slider form-control">
              <span>Number Of Touch Points:</span>
              <Slider
                sx={{
                  width: 260,
                }}
                value={numberOfTouchPoints}
                valueLabelDisplay="auto"
                onChange={(e) => setNumberOfTouchPoints(e.target.value)}
                min={1}
                max={100}
              />
              <input
                type="number"
                min="1"
                max="100"
                className="slider-value"
                value={numberOfTouchPoints}
                onChange={(e) => setNumberOfTouchPoints(e.target.value)}
              />
            </div>
            <Divider />

            <div className="has-slider form-control">
              <span>Interval Between Touch Points [days]:</span>
              <Slider
                sx={{
                  width: 260,
                }}
                value={intervalBetweenTouchPoints}
                valueLabelDisplay="auto"
                onChange={(e) => setIntervalBetweenTouchPoints(e.target.value)}
                min={-1}
                max={300}
              />
              <input
                type="number"
                min="-1"
                max="300"
                className="slider-value"
                value={intervalBetweenTouchPoints}
                onChange={(e) => setIntervalBetweenTouchPoints(e.target.value)}
              />
            </div>
            <Divider />

            <div className="has-slider form-control">
              <span>Other Touch Points:</span>
              <Slider
                sx={{
                  width: 260,
                }}
                value={otherTouchPoints}
                valueLabelDisplay="auto"
                onChange={(e) => setOtherTouchPoints(e.target.value)}
                min={1}
                max={300}
              />
              <input
                type="number"
                min="1"
                max="300"
                className="slider-value"
                value={otherTouchPoints}
                onChange={(e) => setOtherTouchPoints(e.target.value)}
              />
            </div>
            <Divider />

            <div className="form-control">
              <span>Marketing Campaign Outcome:</span>
              <Select
                onChange={(e) => setMarketingCampaignOutcome(e.target.value)}
                value={marketingCampaignOutcome}
                sx={{
                  backgroundColor: "white",
                }}
              >
                "Failure", "Other", "Success", "Unknown"
                <MenuItem value="Failure">Failure</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
                <MenuItem value="Success">Success</MenuItem>
                <MenuItem value="Unknown">Unknown</MenuItem>
              </Select>
            </div>
            <Divider />

            {/* <FormControl component="fieldset">
              <div className="radio-name">Label:</div>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="1" control={<Radio />} label="1" />
              </RadioGroup>
            </FormControl>
            <Divider /> */}
          </div>
        </div>

        <div className="action-buttons">
          <div
            className="random-values secondary"
            onClick={() => setShowModelDetails(true)}
          >
            Model Details
          </div>
          <div className="random-values" onClick={randomValues}>
            Random Values
          </div>
          <div onClick={onSubmitForm} className="predict">
            Predict
          </div>
        </div>
      </form>

      <ModelDetails
        open={showModelDetails}
        onClose={() => setShowModelDetails(false)}
        image={Curve}
      />
      <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <div className="long-term-deposit-result">
          <h2>Long term deposit marketing campaign: </h2>
          <Typography
            fontSize={50}
            style={{ marginBottom: "1rem", color: predict >= 80 ? 'var(--color-logo-primary-green)': 'var(--color-warning)' }}
            sx={{
              marginBottom: "10px !important",
              fontWeight: "bold",
            }}
          >
            {`${predict}% ${predict >= 80 ? 'Successful': 'Unsuccessful'}`}
          </Typography>
          <div
            onClick={() => setDialogOpen(false)}
            className="standard-primary-button"
          >
            Ok
          </div>
        </div>
      </Dialog>
    </>
  );
}
