import React from "react";

export default function PriceIndicatorBar({
  pricing,
  price,
  index,
  markerLeft,
}) {
  const position = index + 1;
  // const left = (price / 3000000) * 100 + "%";
  const left = markerLeft + "%";
  let alignment;
  console.log(position % 4);
  switch (position % 4) {
    case 1:
      alignment = "align-left";
      break;
    case 2:
      alignment = "align-right";
      break;
    case 3:
      alignment = "align-right";
      break;
    case 0:
      alignment = "align-left";
      break;
    default:
      break;
  }
  return (
    <div className={`price-indicator-bar ${alignment}`}>
      {pricing}
      <div style={{ left }} className="marker"></div>
    </div>
  );
}
