import { useBackdrop } from "components/context/BackdropProvider";
import React, { useState } from "react";
import DemoViewHeader from "./DemoViewHeader";

let images = [
  {
    values: [1, 1],
    name: "0fdec733af647b2baf659c55d7b38458e4762fbc",
  },
  {
    values: [0, 0],
    name: "11cdc527bc79dbf5f464cdcaf07d466606352e68",
  },
  {
    values: [1, 0],
    name: "8a98eaa614a6c4edf6f6ba39c58000fa1769fe19",
  },
  {
    values: [0, 0],
    name: "08705be3322e0aa2c460d9fa81ec35624cb19ea4",
  },
  {
    values: [1, 1],
    name: "abd28391d51ea7ead828192c93f5a9a39b78f2e3",
  },
  {
    values: [0, 0],
    name: "62838691def03865788d12d26a694cebbb6bde80",
  },
  {
    values: [0, 0],
    name: "5d3f61ad7058104a1c34becba6e8557555b848c0",
  },
  {
    values: [0, 0],
    name: "7a3e8c0a7e970c7854586013670b181384cc5e8c",
  },
  {
    values: [0, 1],
    name: "82d309f40754bbab3201f76f688c29d8d5da31ba",
  },
  {
    values: [1, 1],
    name: "7905ae99ffb980deefe080f6df8599d09907ba6f",
  },
  {
    values: [1, 1],
    name: "1851dc08329186701142d19f4fb23b52376151c3",
  },

  // {
  //   name: "1ce0719ef9ea179296048a806de77318bad929b2",
  //   values: [],
  // },
  // {
  //   name: "3f4fe854707d12a27f4aa3ae871bb7e28560a0f4",
  //   values: [],
  // },
  // {
  //   name: "6a1fac5e0471eabfcd1718487626b8d61fe2c539",
  //   values: [],
  // },
  // {
  //   name: "8e67ffe1c2fdfae11d30f2430921983a60329ef9",
  //   values: [],
  // },
  // {
  //   name: "71e7bce1495f95d9916801ff1f5836bbaa6a3539",
  //   values: [],
  // },
  // {
  //   name: "417b0a174b958d2ec38fafa8d49754636ec52e5b",
  //   values: [],
  // },
  // {
  //   name: "69737d6047edb4e778c943278761b26b68b1c737",
  //   values: [],
  // },
  // {
  //   name: "cdeb0912c4ade224a886f872148d7892c71f975d",
  //   values: [],
  // },
];
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export default function Histopathological() {
  const [selectItem, setSelecteItem] = useState(null);
  const [lastSelectedImage, setLastSelectedImage] = useState(null);
  const [trueAnswer, setTrueAnswer] = useState(null);

  const { fetching } = useBackdrop();

  const handleImageClick = (image) => {
    // const random = getRandomInt(2, 5) * 1000; // random time;
    getRandomInt(1, 2);

    const random = 2000;// 500;
    setSelecteItem(null);
    setTrueAnswer(null);
    fetching(true);
    setTimeout(() => {
      let predictedValue = image.values[0];
      // cons
      if (predictedValue !== undefined) {
        predictedValue = predictedValue === 0 ? "Healthy" : "Cancer";
      } else {
        predictedValue = "No data";
      }
      fetching(false);
      setSelecteItem({ predictValue: predictedValue });
    }, random);
  };

  const handleTrueAnswer = () => {
    // const random = getRandomInt(2, 5) * 1000;
    const random = 2000;// 250;

    fetching(true);
    setTimeout(() => {
      fetching(false);

      if (!lastSelectedImage) {
        setTrueAnswer(false);
      } else {
        if (lastSelectedImage.values.length === 2) {
          let firstVal = lastSelectedImage.values[0];
          let secondVal = lastSelectedImage.values[1];
          if (firstVal === secondVal) {
            setTrueAnswer(true);
          } else {
            setTrueAnswer(false);
          }
        } else {
          setTrueAnswer(true);
        }
      }
    }, random);
  };
  return (
    <>
      <DemoViewHeader name="Histopathological Cancer Detection" />
      <div id="canner-type-id">
        {images.map((image, index) => {
          return (
            <div
              className="type-image"
              onClick={() => {
                setLastSelectedImage(image);
                handleImageClick(image);
              }}
              key={`${image.name}-image`}
            >
              <img src={`/demo/cancer/${image.name}.png`} alt="" />
            </div>
          );
        })}
      </div>
      {selectItem && (
        <div className="galaxy">
          <div
            // className={`galaxy-result ${
            //   selectItem.predictValue === "Healthy"
            //     ? "color-primary"
            //     : "color-red"
            // }`}
            className={`galaxy-result`}
          >
            Prediction: {selectItem.predictValue}
          </div>
          {trueAnswer !== null && (
            <div
              // className={`galaxy-predict ${!trueAnswer ? "color-red" : ""}`}
              className={`galaxy-predict`}
            >
              {trueAnswer ? "Confirmed" : "Wrong"}
            </div>
          )}
          <div className="galaxy-button" onClick={handleTrueAnswer}>
            True Answer
          </div>
        </div>
      )}
    </>
  );
}
